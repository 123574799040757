import { SUPPORTED_FILE_EXTENSIONS } from '@/backend/types'
import type { Field } from '@/modules/Project/Fields/types'
import { computed, type Ref } from 'vue'
import { resolveFilename } from './resolveFilename'
import { fileURL } from './useProject'
import { useSupportedMimeTypes } from './useSupportedMimeTypes'

/**
 * Provides functionality that is common to:
 * - a file field as represented by a table cell
 * - a file field as represented by an entity view card
 * - any other places we render file fields
 */
export const useFileField = (field: Ref<Field<'file' | 'file_collection' | 'pdf'>>) => {
  const filename = computed(() => resolveFilename(field.value))

  const supportedMimeTypes = useSupportedMimeTypes(field)
  const supportedFileExtensions = [...SUPPORTED_FILE_EXTENSIONS]

  const displayUrl = computed<string | null>(() => fileURL(field.value, 'display'))
  const downloadUrl = computed<string | null>(() => fileURL(field.value, 'download'))

  return {
    /**
     * This is a URL to a PDF version of the file, that can be previewed in the browser. This
     * is used when the user uploads a file that cannot be previewed in the browser (e.g. .docx).
     * The backend converts the file to a PDF so that we can preview it.
     */
    displayUrl,
    /** This is a signed URL to download the exact file the user uploaded. */
    downloadUrl,
    filename,
    supportedMimeTypes,
    supportedFileExtensions,
  }
}
