<script setup lang="ts">
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
// eslint-disable-next-line no-restricted-imports
import PopupMenu from '@/uiKit/PopupMenu.vue'
import { ref } from 'vue'
import FilterItemMatcher, { type ID } from './Filters/FilterItemMatcher.vue'
import type { FilterableProperty } from './Filters/types'
import { FILTER_MENU_ID } from './ModelInputMenuFilters.vue'
import { TYPE_ICON } from './icons'

const props = defineProps<{
  properties: FilterableProperty[]
  selectedProperty: FilterableProperty | null
  selectedMatcher: ID
}>()

const emit = defineEmits<{
  (e: 'update:property', property: FilterableProperty): void
  (e: 'update:matcher', matcher: ID): void
}>()

const initialOpenMenu = props.selectedProperty ? null : 'property'
const openMenu = ref<'property' | 'matcher' | null>(initialOpenMenu)

const onChangePropertyMenu = (open: boolean) => {
  if (open) {
    openMenu.value = 'property'
  } else if (openMenu.value === 'property') {
    openMenu.value = null
  }
}

const onSelectProperty = (property: FilterableProperty) => {
  openMenu.value = null
  emit('update:property', property)
}
</script>

<template>
  <div class="[>*]:h-7 contents select-none">
    <PopupMenu
      :open="openMenu === 'property'"
      :teleport-to="`#${FILTER_MENU_ID}`"
      enable-nested-teleport
      placement="bottom-start"
      :offset="{ mainAxis: 4 }"
      class="rounded-l-md bg-background-gray-subtlest hover:bg-background-gray-subtlest-hovered focus:bg-background-gray-subtlest-hovered"
      :class="openMenu === 'property' && 'bg-background-gray-subtlest-hovered'"
      @change:open="onChangePropertyMenu"
    >
      <template #trigger>
        <div class="flex h-7 items-center gap-2 border-r border-border-subtle px-1.5 py-1">
          <IconSprite
            class="text-icon-subtle"
            size="sm"
            :icon="selectedProperty ? TYPE_ICON[selectedProperty.type] : 'filter'"
          />
          <p
            class="w-full overflow-hidden text-nowrap text-sm-12px-default"
            :class="selectedProperty ? 'text-text' : 'text-text-subtle'"
          >
            {{ selectedProperty?.name ?? 'Select' }}
          </p>
        </div>
      </template>
      <template #dropdown>
        <ListMenu :items="properties.map((p) => ({ id: p.name, data: p }))">
          <template #item="{ active, item, key, setActiveItem }">
            <ListMenuItem
              :key="key"
              :label="item.data.name"
              :icon="TYPE_ICON[item.data.type]"
              :active="active"
              :aria-selected="active"
              @mousemove="setActiveItem(key)"
              @select="onSelectProperty(item.data)"
            />
          </template>
        </ListMenu>
      </template>
    </PopupMenu>
    <FilterItemMatcher
      class="h-7 border-r border-border-subtle bg-background-gray-subtlest focus:outline-none focus-visible:!bg-background-gray-subtlest-hovered"
      :class="selectedProperty && '!text-text hover:!bg-background-gray-subtlest-hovered'"
      :disabled="!selectedProperty"
      :matcher-name="selectedMatcher"
      :values="['']"
      :type="selectedProperty?.type ?? 'text'"
      :option-count="0"
      :positioning="{ placement: 'bottom-start' }"
      :teleport-to="`#${FILTER_MENU_ID}`"
      @match="$emit('update:matcher', $event)"
    />
    <div
      class="flex h-7 min-w-14 items-stretch justify-stretch overflow-hidden rounded-r-md bg-background-gray-subtlest focus-within:bg-background-gray-subtlest-hovered"
    >
      <slot name="value">
        <div class="flex items-center pl-2 text-sm-12px-default text-text-disabled">Select...</div>
      </slot>
    </div>
  </div>
</template>
