import type { SupportedMimeType } from '@/backend/types'
import { useSupportedMimeTypes } from '@/modules/Project/useSupportedMimeTypes'
import { useDropZone } from '@vueuse/core'
import { computed, ref, toValue, type MaybeRefOrGetter } from 'vue'

export function useLibraryDropzone(props: {
  dropZoneRef: MaybeRefOrGetter<HTMLElement | undefined | null>
  onDrop?: (files: File[]) => void
}) {
  const supportedMimeTypes = useSupportedMimeTypes()
  const draggedFiles = ref<{ type: SupportedMimeType }[]>([])
  const dropTarget = computed(() => toValue(props.dropZoneRef))

  const { isOverDropZone } = useDropZone(dropTarget, {
    multiple: true,
    preventDefaultForUnhandled: true,
    dataTypes: supportedMimeTypes,
    onEnter(files, event) {
      const items = [...(event.dataTransfer?.items || [])]
      draggedFiles.value = items
        .filter((item) => item.kind === 'file' && supportedMimeTypes.value.includes(item.type))
        .map(({ type }) => ({ type: type as SupportedMimeType }))
    },
    onDrop(files) {
      if (!files) return
      const validFiles = files.filter((f) => supportedMimeTypes.value.includes(f.type))
      props.onDrop?.(validFiles)
    },
  })

  return { isOverDropZone, draggedFiles }
}
