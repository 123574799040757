import { PropertyType, type CollectionSubPropertyType } from '@/backend/types'
import type {
  UpdateCollectionPropertyConfig,
  UpdateSelectPropertyConfig,
} from '@/backend/updateProperty'
import type { Property, PropertyInput } from '@/modules/Project/Properties/types'
import { deepStripUndefined } from '@/shared/utils'
import { convertPropertyType } from '@/shared/utils/property'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { isSelectProperty } from '@/shared/utils/typeGuards'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'
import { useCloned } from '@vueuse/core'
import { dequal } from 'dequal'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import { useProject } from './useProject'

/**
 * A pinia store backend for editing a project property.
 *
 * We use a store to enable the app to visually react to changes in the property,
 * for example, changes to input ids highlight table columns, etc.
 */
export const useProperty = defineStore('property', () => {
  const { captureAnalyticsEvent } = useAnalytics()
  const projectStore = useProject()

  const propIndex = computed(() =>
    projectStore.selectedPropertyId
      ? projectStore.propIdToIndex[projectStore.selectedPropertyId]
      : null,
  )

  /** The currently selected property as it is saved on the backend. */
  const savedProperty = computed<Property | null>(() =>
    propIndex.value !== null ? (projectStore.properties[propIndex.value] ?? null) : null,
  )

  const sidebarIsOpen = ref(false)

  const { cloned: editedProperty, sync: syncProperty } = useCloned<Property | null>(savedProperty, {
    deep: true,
    manual: true,
  })

  /**
   * Used when updating a select property's options. We need to send
   * this to the backend so that field values can be remapped.
   */
  const upsertOptions = ref<UpdateSelectPropertyConfig['upsertOptions']>([])
  const removeOptions = ref<UpdateSelectPropertyConfig['removeOptions']>([])
  const upsertCollectionProperties = ref<
    UpdateCollectionPropertyConfig['upsertCollectionProperties']
  >([])
  const removeCollectionProperties = ref<
    UpdateCollectionPropertyConfig['removeCollectionProperties']
  >([])

  watch(
    () => savedProperty.value,
    (newProperty, oldProperty) => {
      const noChange = dequal(newProperty, oldProperty)
      if (noChange) {
        /**
         * Don't sync the property if the new property is the same as the old property,
         * as this will clear any in-progress edits. This can happen when we handle the
         * `'property:updated'` websocket message that arrives shortly after the user has
         * created a property.
         */
        return
      }

      syncProperty()
      upsertOptions.value = []
      removeOptions.value = []
      upsertCollectionProperties.value = []
      removeCollectionProperties.value = []
    },
    { immediate: true },
  )

  /**
   * The edited input ids of the property. Set to original on prop selection.
   */
  const editedInputs = computed(() => editedProperty.value?.inputs ?? [])
  const includesInputId = (propertyId: string, entityId: string) =>
    editedInputs.value.some(
      (input) => input.propertyId === propertyId && (!entityId || input.entityId === entityId),
    )

  const editedConfig = computed(() =>
    editedProperty.value && 'config' in editedProperty.value ? editedProperty.value.config : null,
  )

  /**
   * List of other properties in the project. These are input id candidates.
   *
   * NOTE: This is probably not final. Not all properties can be input ids, and
   * it's possible we will decide to only allow those properties to the left
   * of the current property in the table, to be input ids.
   */
  const otherProperties = computed(() =>
    projectStore.properties.filter((p) => p.id !== savedProperty.value?.id),
  )

  /**
   * Resets edited values to those of the original.
   * Should be called by a single app component, as a reaction to changing
   * `useProject().selectedPropertyId`
   */
  const reset = () => {
    upsertOptions.value = []
    removeOptions.value = []
    upsertCollectionProperties.value = []
    removeCollectionProperties.value = []
    syncProperty()
  }

  /**
   * Signals if any changes have been made to the property
   */
  const isDirty = computed(() => {
    const hasUpsertedOptions = upsertOptions.value && upsertOptions.value.length > 0
    const hasRemovedOptions = removeOptions.value && removeOptions.value.length > 0
    const hasUpsertedCollectionProperties =
      upsertCollectionProperties.value && upsertCollectionProperties.value.length > 0
    const hasRemovedCollectionProperties =
      removeCollectionProperties.value && removeCollectionProperties.value.length > 0

    if (
      hasUpsertedOptions ||
      hasRemovedOptions ||
      hasUpsertedCollectionProperties ||
      hasRemovedCollectionProperties
    ) {
      /**
       * There is an edge case where the property might not have changed but
       * we still want to send an update. This is the case where the user
       * shifts select values around, e.g.
       * 1. "Option A" -> "Option B"
       * 2. "Option B" -> "Option A"
       * In this case, the options have stayed the same, but we still need to
       * send an update to the backend so that field values are remapped.
       */
      return true
    }

    const propertyHasChanged = !dequal(
      deepStripUndefined(savedProperty.value ?? {}),
      deepStripUndefined(editedProperty.value ?? {}),
    )
    return propertyHasChanged
  })

  const hasUnsavedOptions = computed(() => {
    if (!isSelectProperty(editedProperty.value)) {
      return false
    }

    return (upsertOptions.value || []).length > 0 || (removeOptions.value || []).length > 0
  })

  /**
   * Selects or deselects an input id for the property
   */
  const toggleInputId = ({
    propertyId,
    entityId,
    viaPropertyId,
  }: Pick<PropertyInput, 'entityId' | 'propertyId' | 'viaPropertyId'>) => {
    const index = editedInputs.value.findIndex(
      (input) =>
        input.propertyId === propertyId &&
        (!entityId || input.entityId === entityId) &&
        (!viaPropertyId || input.viaPropertyId === viaPropertyId),
    )
    if (index > -1) {
      editedInputs.value.splice(index, 1)
      captureAnalyticsEvent(ANALYTICS_EVENT.INPUT_SELECTED, { propertyId, entityId, value: false })
    } else {
      editedInputs.value.push({ propertyId, entityId, viaPropertyId })
      captureAnalyticsEvent(ANALYTICS_EVENT.INPUT_SELECTED, { propertyId, entityId, value: true })
    }
  }

  /**
   * Replaces an input if it exists, otherwise adds it.
   */
  const setInput = ({
    propertyId,
    entityId,
    filters,
  }: {
    propertyId: PropertyInput['propertyId']
    entityId?: PropertyInput['entityId']
    filters?: PropertyInput['filters'] | null
  }) => {
    if (!editedProperty.value) {
      return
    }
    const index = editedInputs.value.findIndex(
      (input) => input.propertyId === propertyId && (input.entityId || null) === (entityId || null),
    )
    if (index > -1) {
      editedProperty.value.inputs = editedProperty.value.inputs.with(index, {
        propertyId,
        entityId,
        filters: filters || undefined,
      })
    } else {
      editedInputs.value.push({ propertyId, entityId, filters: filters || undefined })
    }
  }

  const updateSelectOption = (
    {
      value: newValue,
      color: newColor,
      tool_fallback: newFallback,
    }: { value: string; color?: string | null; tool_fallback?: boolean | null },
    oldValue: string,
  ) => {
    const pristineExistingOptions = isSelectProperty(savedProperty.value)
      ? savedProperty.value.config.options
      : []
    const stagedUpsertOptions = [...(upsertOptions.value ?? [])]

    const existingOption = pristineExistingOptions.find((o) => o.value === oldValue)

    if (existingOption) {
      // existing option case
      const stagedIndex = stagedUpsertOptions.findIndex((o) => o.value === oldValue)
      const updateObject = {
        value: existingOption.value,
        new_value: newValue,
        color: newColor,
        tool_fallback: newFallback || false,
      }

      if (stagedIndex !== undefined && stagedIndex !== -1) {
        // staged case
        stagedUpsertOptions.splice(stagedIndex, 1, updateObject)
      } else {
        // stage new updated
        stagedUpsertOptions.push(updateObject)
      }
    } else {
      // new option case
      const stagedIndex = stagedUpsertOptions.findIndex((o) => o.value === oldValue)

      if (stagedIndex !== undefined && stagedIndex !== -1) {
        // staged case
        stagedUpsertOptions[stagedIndex].value = newValue
        stagedUpsertOptions[stagedIndex].color = newColor
      } else {
        // stage new updated
        stagedUpsertOptions.push({
          value: newValue,
          color: newColor,
          tool_fallback: newFallback || false,
        })
      }
    }

    upsertOptions.value = stagedUpsertOptions
  }

  const deleteSelectOption = (value: string) => {
    const existingOption = isSelectProperty(savedProperty.value)
      ? savedProperty.value.config.options.find((option) => option.value === value)
      : undefined

    if (existingOption) {
      const stagedToRemove = [...(removeOptions.value ?? [])]
      stagedToRemove.push(value)
      removeOptions.value = stagedToRemove
    }

    // it might be a newly created option we just need to remove it from the staged upsert state
    const existingUpsertIndex = upsertOptions.value?.findIndex((o) => o.value === value)
    if (existingUpsertIndex !== undefined && existingUpsertIndex !== -1) {
      upsertOptions.value?.splice(existingUpsertIndex, 1)
    }
  }

  const markOptionAsDefault = (value: string | null) => {
    if (!isSelectProperty(editedProperty.value) || !editedProperty.value.config) {
      return
    }
    editedProperty.value.config.defaultOption = value
  }

  const createSelectOption = (newOption: { value: string; color?: string | null }) => {
    // If there is currently an option with the same value, don't create a new one because the desired color might be lost.
    if (
      isSelectProperty(editedProperty.value) &&
      editedProperty.value.config.options.some((opt) => opt.value === newOption.value)
    ) {
      return
    }

    const stagedUpsertOptions = [...(upsertOptions.value ?? [])]
    // don't allow empty duplicates
    if (stagedUpsertOptions.some((o) => o.value === newOption.value)) return

    // if recreating an option that was deleted, remove it from the delete list
    if (removeOptions.value?.includes(newOption.value)) {
      removeOptions.value = removeOptions.value.filter((o) => o !== newOption.value)
    }

    stagedUpsertOptions.push({ ...newOption, tool_fallback: false })
    upsertOptions.value = stagedUpsertOptions
  }

  const toggleSelectOption = (newOption: { value: string; color?: string | null }) => {
    const isSelectType =
      savedProperty.value?.type === PropertyType.single_select ||
      savedProperty.value?.type === PropertyType.multi_select ||
      savedProperty.value?.type === PropertyType.user_select

    const stagedUpsertOption = [...(upsertOptions.value ?? [])]
    const isAlreadyUpserted = stagedUpsertOption.some((o) => o.value === newOption.value)
    const isAlreadyRemoved = removeOptions.value?.includes(newOption.value)
    const isAlreadySaved =
      isSelectType && savedProperty.value.config.options.some((o) => o.value === newOption.value)
    if (isAlreadyUpserted || (isAlreadySaved && !isAlreadyRemoved)) {
      deleteSelectOption(newOption.value)
    } else {
      createSelectOption(newOption)
    }
  }

  const markOptionsAsFallback = (
    newOptions: {
      value: string
      color?: string | null
      tool_fallback?: boolean | null
    }[],
  ) => {
    const options =
      savedProperty.value?.type === PropertyType.single_select ||
      savedProperty.value?.type === PropertyType.multi_select
        ? savedProperty.value.config.options
        : []

    const stagedUpsertOptions = options.map(
      (o: { value: string; color?: string | null; tool_fallback?: boolean | null }) => ({
        ...o,
        tool_fallback: newOptions.some((newOption) => newOption.value === o.value),
      }),
    )

    upsertOptions.value = stagedUpsertOptions
  }

  const visibleOptions = computed(() => {
    const isSelectType = isSelectProperty(savedProperty.value)

    const upsertedMap = Object.fromEntries(upsertOptions.value?.map((o) => [o.value, o]) ?? [])

    const options = isSelectType ? savedProperty.value.config.options : []

    return (
      options
        .filter((option) => !removeOptions.value?.includes(option.value))
        .map((option) => ({
          // make sure we use the staged value and color if it exists
          value: option.value,
          color: upsertedMap[option.value]?.color ?? option.color,
          tool_fallback: option.tool_fallback,
        }))
        .concat(
          // concat the inserted options at the end
          upsertOptions.value
            ?.filter((option) => !options.some((o) => o.value === option.value))
            .map((x) => ({
              value: x.value,
              color: x.color,
              tool_fallback: x.tool_fallback ?? false,
            })) ?? [],
        ) ?? []
    )
  })

  const updateCollectionProperty = (
    { name: newName, type: newType }: { name: string; type: CollectionSubPropertyType },
    oldName: string,
  ) => {
    const isCollectionType = savedProperty.value?.type === PropertyType.collection
    const pristineExistingCollectionProperties = isCollectionType
      ? (savedProperty.value?.config.properties ?? [])
      : []
    const stagedUpsertCollectionProperties = [...(upsertCollectionProperties.value ?? [])]

    const existingCollectionProperty = pristineExistingCollectionProperties.find(
      (p) => p.name === oldName,
    )

    if (existingCollectionProperty) {
      // existing collection property case
      const stagedIndex = stagedUpsertCollectionProperties.findIndex((p) => p.name === oldName)

      const updateObject = {
        name: existingCollectionProperty.name,
        new_name: newName,
        type: newType,
      }

      if (stagedIndex !== undefined && stagedIndex !== -1) {
        // staged case
        stagedUpsertCollectionProperties.splice(stagedIndex, 1, updateObject)
      } else {
        // stage new updated
        stagedUpsertCollectionProperties.push(updateObject)
      }
    } else {
      // new option case
      const stagedIndex = stagedUpsertCollectionProperties.findIndex((p) => p.name === oldName)

      if (stagedIndex !== undefined && stagedIndex !== -1) {
        // staged case
        stagedUpsertCollectionProperties[stagedIndex].name = newName
        stagedUpsertCollectionProperties[stagedIndex].type = newType
      } else {
        // stage new updated
        stagedUpsertCollectionProperties.push({ name: newName, type: newType })
      }
    }

    upsertCollectionProperties.value = stagedUpsertCollectionProperties
  }

  const deleteCollectionProperty = (name: string) => {
    const isCollectionType = savedProperty.value?.type === PropertyType.collection

    const existingCollectionProperty = isCollectionType
      ? savedProperty.value.config.properties?.find((p) => p.name === name)
      : undefined

    if (existingCollectionProperty) {
      const stagedToRemove = [...(removeCollectionProperties.value ?? [])]
      stagedToRemove.push(name)
      removeCollectionProperties.value = stagedToRemove
    }

    // it might be a newly created property we just need to remove it from the staged upsert state
    const existingUpsertIndex = upsertCollectionProperties.value?.findIndex((p) => p.name === name)
    if (existingUpsertIndex !== undefined && existingUpsertIndex !== -1) {
      upsertCollectionProperties.value?.splice(existingUpsertIndex, 1)
    }
  }

  const createCollectionProperty = (newCollectionProperty: {
    name: string
    type: CollectionSubPropertyType
  }) => {
    const stagedUpsertCollectionProperties = [...(upsertCollectionProperties.value ?? [])]
    // don't allow empty duplicates
    if (stagedUpsertCollectionProperties.some((p) => p.name === newCollectionProperty.name)) return

    // if recreating an option that was deleted, remove it from the delete list
    if (removeCollectionProperties.value?.includes(newCollectionProperty.name)) {
      removeCollectionProperties.value = removeCollectionProperties.value.filter(
        (p) => p !== newCollectionProperty.name,
      )
      return
    }

    stagedUpsertCollectionProperties.push(newCollectionProperty)
    upsertCollectionProperties.value = stagedUpsertCollectionProperties
  }

  const visibleProperties = computed(() => {
    const isCollectionType = savedProperty.value?.type === PropertyType.collection

    const upsertedMap = Object.fromEntries(
      upsertCollectionProperties.value?.map((p) => [p.name, p]) ?? [],
    )

    const properties = isCollectionType ? (savedProperty.value?.config.properties ?? []) : []

    return (
      properties
        .filter((property) => !removeCollectionProperties.value?.includes(property.name))
        .map((property) => ({
          // make sure we use the staged value and color if it exists
          name: property.name,
          type: upsertedMap[property.name]?.type ?? property.type,
        }))
        .concat(
          // concat the inserted options at the end
          upsertCollectionProperties.value
            ?.filter((property) => !properties.some((p) => p.name === property.name))
            .map((x) => ({ name: x.name, type: x.type ?? 'text' })) ?? [],
        ) ?? []
    )
  })

  const setName = (name: string) => {
    assertIsNotNullOrUndefined(editedProperty.value)
    editedProperty.value.name = name
  }

  /**
   * @deprecated Unused, but let's wait a bit before deleting, might be useful
   * when we add prop duplication -> fully optimistic PropertyEditMenu where type
   * can be changed without saving first (Ivan, 2025-02-07)
   */
  const setType = (type: PropertyType) => {
    assertIsNotNullOrUndefined(editedProperty.value)
    editedProperty.value = convertPropertyType(editedProperty.value, type)
  }

  return {
    editedInputs,
    includesInputId,
    setInput,
    editedProperty,
    setName,
    editedConfig,
    deleteSelectOption,
    visibleOptions,
    isDirty,
    hasUnsavedOptions,
    otherProperties,
    savedProperty,
    reset,
    toggleInputId,
    updateSelectOption,
    createSelectOption,
    toggleSelectOption,
    sidebarIsOpen,
    createCollectionProperty,
    deleteCollectionProperty,
    updateCollectionProperty,
    visibleProperties,
    markOptionAsDefault,
    markOptionsAsFallback,
    upsertOptions,
    removeOptions,
    upsertCollectionProperties,
    removeCollectionProperties,
    setType,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProperty, import.meta.hot))
}
