import { gmailIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/Gmail/gmailIntegration'
import { googleDriveIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/googleDriveIntegration'
import { outlookIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/Outlook/outlookIntegration'
import { sharepointIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/SharePoint/sharepointIntegration'
import type { Integration, PreviewIntegration } from './integrationTypes'

/**
 * Backend-supported integrations.
 *
 * These integrations are supported by the backend and can be connected to a workspace.
 */
export const integrations: Record<string, Integration> = {
  [googleDriveIntegration.id]: googleDriveIntegration,
}

/**
 * UI-only preview integrations.
 *
 * These integrations are not supported by the backend yet, but we want to show them in the UI
 * to give the user a sense of what's possible.
 */
export const previewIntegrations: Record<string, PreviewIntegration> = {
  [sharepointIntegration.id]: sharepointIntegration,
  [outlookIntegration.id]: outlookIntegration,
  [gmailIntegration.id]: gmailIntegration,
}
