<script setup lang="ts">
import { PropertyType } from '@/backend/types'
import { useProperty } from '@/modules/Project/useProperty'
import LabelValueMenuItem from '@/sharedComponents/LabelValueMenuItem.vue'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import ColorSwatch from '@/uiKit/ColorSwatch.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
// eslint-disable-next-line no-restricted-imports
import PopupMenu from '@/uiKit/PopupMenu.vue'
import { computed, onMounted, ref, watch } from 'vue'
import type { Item, Option } from './SelectTypeOptions.vue'

const props = withDefaults(
  defineProps<{
    filteredOptions?: Option[]
  }>(),
  {
    filteredOptions: () => [],
  },
)

const propertyStore = useProperty()

const defaultOption: Item = {
  id: 'option-default',
  data: {
    value: 'Keep empty',
    color: null,
    tool_fallback: false,
  },
}

const fallbackOptions = ref<Item[]>([])
const selectedFallbackItems = ref([defaultOption])

const isFallbackSubmenuOpen = ref(false)
const FALLBACK_SUBMENU_ID = 'select-type-options-preset-submenu'

// helper to watch for deletions in filteredOptions - edge case
const newOptionsValues = computed(() => {
  return props.filteredOptions
    .map((option) => (option ? option.value : undefined))
    .filter((item) => item !== undefined)
})

watch(newOptionsValues, (newValues, oldValues) => {
  if (newValues.length < oldValues.length) {
    initFallbackOptions()
  }
})

const isDeafaultOptionSelected = computed(
  () =>
    selectedFallbackItems.value.length === 1 &&
    selectedFallbackItems.value[0].data.value === defaultOption.data.value,
)

const initFallbackOptions = () => {
  fallbackOptions.value = [
    defaultOption,
    ...props.filteredOptions.map((option, index) => ({
      id: `option-${index}`,
      data: option,
    })),
  ]
  const selectedItems = fallbackOptions.value.filter((option: Item) => option.data.tool_fallback)
  selectedFallbackItems.value = selectedItems.length > 0 ? selectedItems : [defaultOption]
}

const isOptionSelected = (item: Item) => {
  return selectedFallbackItems.value.some(
    (selectedItem: Item) => selectedItem.data.value === item.data.value,
  )
}

// single select
const onToggleFallbackOption = (item: Item) => {
  if (!item) return

  propertyStore.markOptionsAsFallback([item.data])
  selectedFallbackItems.value = [item]
}

// multi select
const onToggleFallbackOptions = (item: Item) => {
  if (!item) return

  const action = isOptionSelected(item) ? 'add' : 'remove'
  const items =
    action === 'add'
      ? selectedFallbackItems.value.filter(
          (selectedItem: Item) => selectedItem.data.value !== item.data.value,
        )
      : [...selectedFallbackItems.value, item]

  // mark the selected options as fallback in store
  propertyStore.markOptionsAsFallback(items.map((item: Item) => item.data))
  selectedFallbackItems.value = items
}

onMounted(() => {
  initFallbackOptions()
})
</script>

<template>
  <PopupMenu
    placement="right-start"
    :offset="{ alignmentAxis: -2, mainAxis: 4 }"
    class="w-full"
    cover-trigger
    :open="isFallbackSubmenuOpen"
    @change:open="isFallbackSubmenuOpen = $event"
  >
    <template #trigger>
      <LabelValueMenuItem
        v-if="propertyStore.editedProperty?.type === PropertyType.single_select"
        id="input-combobox"
        ref="inputTrigger"
        :label="selectedFallbackItems[0]?.data.value"
        aria-label="Selected no match found"
        icon-submenu="chevron-select"
        alignment="top"
        class="text-text-subtle"
        :class="{
          'bg-background-transparent-hovered': isFallbackSubmenuOpen,
        }"
        :submenu="{
          isOpen: isFallbackSubmenuOpen,
          id: FALLBACK_SUBMENU_ID,
        }"
      >
        <template #prefix>
          <ColorSwatch
            :value="selectedFallbackItems[0]?.data.color"
            class="mr-1.5"
          />
        </template>
      </LabelValueMenuItem>
      <LabelValueMenuItem
        v-else
        id="input-combobox"
        ref="inputTrigger"
        :label="isDeafaultOptionSelected ? defaultOption.data.value : ''"
        aria-label="Selected no match found"
        icon-submenu="chevron-select"
        alignment="top"
        class="text-text-subtle"
        :class="{
          'bg-background-transparent-hovered': isFallbackSubmenuOpen,
        }"
        :submenu="{
          isOpen: isFallbackSubmenuOpen,
          id: FALLBACK_SUBMENU_ID,
        }"
      >
        <template #prefix>
          <ColorSwatch
            v-if="isDeafaultOptionSelected"
            :value="null"
            class="mr-1.5"
          />
          <template v-else>
            <BadgeItem
              v-for="item in selectedFallbackItems.filter(
                (item: Item) => item.data.value !== defaultOption.data.value,
              )"
              :key="item.id"
              variant="neutral"
              :label="item.data.value"
              :rainbow-color="item.data.color ? item.data.color : 'gray'"
              size="sm"
            />
          </template>
        </template>
      </LabelValueMenuItem>
    </template>
    <template #dropdown>
      <ListMenu
        role="listbox"
        class="min-w-[240px]"
        aria-label="Select no match found option"
        search-by-field="value"
        :group-by-predicate="(item: Item) => (item.data.tool_fallback ? 'true' : 'false')"
        :group-order="['true', 'false']"
        :scroll-level="'group'"
        :items="fallbackOptions"
      >
        <template #item="{ key, item, active, setActiveItem }">
          <template v-if="propertyStore.editedProperty?.type === PropertyType.single_select">
            <ListMenuItem
              :key="key"
              :label="item.data.value"
              :active="active"
              :aria-selected="isOptionSelected(item)"
              default-hover-disabled
              @mousemove="setActiveItem(key)"
              @click="onToggleFallbackOption(item)"
            >
              <template #prefix>
                <div class="flex size-5 items-center justify-center">
                  <IconSprite
                    :icon="isOptionSelected(item) ? 'check' : 'blank'"
                    class="text-icon-subtle"
                  />
                </div>
                <div class="flex size-5 items-center justify-center">
                  <ColorSwatch :value="item.data.color" />
                </div>
              </template>
            </ListMenuItem>
          </template>
          <template v-else>
            <ListMenuCheckboxItem
              :key="key"
              :label="item.data.value"
              :active="active"
              :checked="isOptionSelected(item)"
              :aria-selected="isOptionSelected(item)"
              default-hover-disabled
              @mousemove="setActiveItem(key)"
              @click="onToggleFallbackOptions(item)"
            >
              <template #prefix><ColorSwatch :value="item.data.color" /> </template>
            </ListMenuCheckboxItem>
          </template>
        </template>
      </ListMenu>
    </template>
  </PopupMenu>
</template>
