import { ref, shallowRef, toRaw, type Component } from 'vue'

/**
 * When loading a lazy component, we need it to be rendered in the Vue tree
 * before we pass it `:open=true` because otherwise its fade in animation
 * wouldn't be triggered.
 *
 * This just colocates functions needed to track component lifecycle and open the dialog.
 */
export function useLazyIntegrationDialog() {
  const isDialogOpen = ref(false)
  const integrationDialog = shallowRef<Component>()

  function onDialogMounted() {
    isDialogOpen.value = true
  }

  function loadDialog(component: Component) {
    const prevComponent = toRaw(integrationDialog.value)
    integrationDialog.value = component

    // Maybe we want to open a dialog that is already mounted.
    // Then we can open it immediately, otherwise we rely on the `onDialogMounted`
    // function to open it.
    if (prevComponent === component) {
      isDialogOpen.value = true
    }
  }

  return {
    isDialogOpen,
    onDialogMounted,
    loadDialog,
    integrationDialog,
  }
}
