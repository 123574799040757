import { watch, type Ref } from 'vue'
import { useLDClient } from '../App/useLDClient'
import { useUser } from '../IdentityAndAccess/useUser'
import type { Workspace } from './useWorkspaces'

/**
 * On LaunchDarkly we can target either workspaces or users for specific flag values.
 * This PR watches the workspace and user and sends updates the LaunchDarkly context
 * when either of them changes so that we always receive the correct flag values.
 */
export const useLaunchDarklyContext = (workspace: Ref<Workspace | undefined>) => {
  const launchDarkly = useLDClient()
  const userStore = useUser()
  watch(
    () => [workspace.value, userStore.user, launchDarkly.client] as const,
    async ([ws, user, ldClient]) => {
      if (!ws || !user || !ldClient) return
      await ldClient.waitForInitialization()
      ldClient.identify({
        kind: 'multi',
        user: {
          email: user.email,
          anonymous: false,
          key: user.id,
          name: [user.firstName, user.lastName].filter(Boolean).join(' '),
        },
        workspace: {
          key: ws.id,
          name: ws.name,
          createdAt: ws.createdAt,
        },
      })
    },
    { immediate: true },
  )
}
