import { toast } from '@/shared/toast'
import { useSetFieldValue } from '@/shared/useSetFieldValue'
import { normaliseLocalisedNumberString } from '@/shared/utils/string'
import { useCloned } from '@vueuse/core'
import { computed, type Ref } from 'vue'
import type { Field } from './Fields/types'
import { DEFAULT_FORMAT } from './Fields/utils/number'
import type { Property } from './Properties/types'
import { useFieldValue } from './useFieldValue'
import { useSerializeFieldToText } from './useSerializeFieldToText'

/**
 * Contains logic and state that is common to any place we want to render a number field, e.g.
 * in the project table or entity view.
 */
export const useNumberField = (
  field: Ref<Field<'number'>>,
  config: Ref<Property<'number'>['config']>,
) => {
  const fieldValue = useFieldValue(field)

  /**
   * The format to apply to number field values, regardless of whether
   * we are using a custom or automatic format.
   */
  const format = computed(() => {
    if (!config.value.isCustomFormat) {
      return DEFAULT_FORMAT
    }

    return config.value.format
  })

  const serializeField = useSerializeFieldToText()
  /**
   * The value that is saved in the database, formatted according to the property
   * config and the user's locale.
   */
  const formattedSavedValue = computed(() => serializeField(field.value))

  const { cloned: inputFieldValue, sync: syncLocalValue } = useCloned(formattedSavedValue)

  // The styles that show the number in red when it's negative.
  const RED_STYLES: Array<Property<'number'>['config']['format']['negativeFormat']> = [
    'colored',
    'colored_parentheses',
  ]
  const shouldBeRed = computed(
    () => RED_STYLES.includes(format.value.negativeFormat) && Number(fieldValue.value) < 0,
  )

  const setFieldValue = useSetFieldValue()
  const saveFieldValue = async ({
    projectId,
    workspaceId,
  }: {
    projectId: string
    workspaceId: string
  }) => {
    if (formattedSavedValue.value === inputFieldValue.value) return

    const res = await setFieldValue({
      entityId: field.value.entityId,
      projectId,
      workspaceId,
      fields: {
        [field.value.propertyId]: {
          field: field.value,
          newValue: normaliseLocalisedNumberString(inputFieldValue.value),
        },
      },
    })

    if (!res.ok) {
      toast.error(`Failed to update field: ${res.error.message}`)
    }
  }

  /**
   * Will be true if the tool ran and returned `null`, i.e. it couldn't find a number in
   * the input.
   */
  const toolReturnedNull = computed(
    () => field.value.toolValue === null && field.value.toolValueUpdatedBy !== null,
  )

  return {
    inputFieldValue,
    syncLocalValue,
    shouldBeRed,
    saveFieldValue,
    format,
    toolReturnedNull,
  }
}
