import { useIntegrationStore } from '@/modules/Workspaces/KnowledgeHub/useIntegrationStore'
import { useTimeoutPoll } from '@vueuse/core'
import { computed, watch } from 'vue'

/**
 * Poll for connection status updates
 *
 * There is currently (2024-12-12) no other way to get updates from the backend about
 * progress of conenction sync status. This is a (probably) temporary solution to
 * be able to show progress of connection sync status without user needing to refresh.
 */
export function useIntegrationPolling() {
  const integrationsStore = useIntegrationStore()
  const { pause, resume } = useTimeoutPoll(() => integrationsStore.load(), 5_000, {
    // Only start polling when there are syncing connections.
    immediate: false,
  })

  /**
   * We are only interested that connections that started to sync.
   * Before they finish, their `items_synced` will be `null`.
   */
  const hasSyncingConnections = computed<boolean>(() => {
    return integrationsStore.connections.some((c) => {
      return c.status === 'started' && c.itemsSynced === null
    })
  })

  watch(
    () => hasSyncingConnections.value,
    (isSyncing) => (isSyncing ? resume() : pause()),
    { immediate: true },
  )
}
