import type { RouteRecordRaw } from 'vue-router'
import ProjectSidebar from '../Projects/ProjectSidebar.vue'
import Case from './Case.vue'
import CaseBreadcrumbs from './CaseBreadcrumbs.vue'
import CasesHomepage from './CasesHomepage.vue'
import CasesHomepageCrumbs from './CasesHomepageCrumbs.vue'
import CreateCase from './CreateCase.vue'

export const casesRoutes: RouteRecordRaw[] = [
  {
    path: 'cases',
    name: 'WorkspaceCases',
    props: {
      default: true,
      Sidebar: (route) => ({
        name: 'Sidebar',
        workspaceId: route.params.workspaceId,
        open: true,
      }),
      BreadCrumbs: (route) => ({
        name: 'BreadCrumbs',
        workspaceId: route.params.workspaceId,
      }),
    },
    components: {
      BreadCrumbs: CasesHomepageCrumbs,
      default: CasesHomepage,
      Sidebar: ProjectSidebar,
    },
  },
  {
    path: 'cases/new/:projectId',
    name: 'NewCase',
    props: {
      default: (route) => ({
        workspaceId: route.params.workspaceId,
        projectId: route.params.projectId,
      }),
      Sidebar: (route) => ({
        workspaceId: route.params.workspaceId,
        open: true,
      }),
      BreadCrumbs: true,
    },
    components: {
      default: CreateCase,
      Sidebar: ProjectSidebar,
      BreadCrumbs: CaseBreadcrumbs,
    },
  },
  {
    path: 'cases/:caseId',
    name: 'Case',
    props: {
      default: (route) => ({
        workspaceId: route.params.workspaceId,
        caseId: route.params.caseId,
      }),
      Sidebar: (route) => ({
        workspaceId: route.params.workspaceId,
        open: true,
      }),
      BreadCrumbs: true,
    },
    components: {
      default: Case,
      Sidebar: ProjectSidebar,
      BreadCrumbs: CaseBreadcrumbs,
    },
  },
]
