import type { components } from '@/api'
import type { PropertyWithoutConfig } from '@/modules/Project/Properties/types'
import { put } from './client'

export type UpdatePropertyPath = `/api/workspaces/${string}/projects/${string}/properties/${string}`
export type UpdatePropertyRequest = components['schemas']['Projects.UpdatePropertyRequest']
export type UpdatePropertyResponse = components['schemas']['Projects.PropertyResponse']

export type BaseUpdatePropertyParams = {
  name: string
  tool: UpdatePropertyRequest['tool']
  isGrounded: UpdatePropertyRequest['is_grounded']
  description: string | null
  inputs: Exclude<UpdatePropertyRequest['inputs'], undefined | null>
}

export type UpdateSelectPropertyConfig = {
  defaultOption?: components['schemas']['Projects.UpdateSingleSelectPropertyConfig']['default_option']
  maxSelected?: components['schemas']['Projects.SelectMaxSelected']
  removeOptions?: components['schemas']['Projects.UpdateSingleSelectPropertyConfig']['remove_options']
  upsertOptions?: components['schemas']['Projects.UpdateSingleSelectPropertyConfig']['upsert_options']
}

export type UpdateCollectionPropertyConfig = {
  upsertCollectionProperties?: components['schemas']['Projects.UpdateCollectionPropertyConfig']['upsert_properties']
  removeCollectionProperties?: components['schemas']['Projects.UpdateCollectionPropertyConfig']['remove_properties']
}

export type UpdatePropertyParams = BaseUpdatePropertyParams &
  (
    | {
        type: PropertyWithoutConfig['type']
      }
    | {
        type: 'single_select' | 'multi_select' | 'user_select'
        config: UpdateSelectPropertyConfig
      }
    | {
        type: 'pdf'
        config: {
          splitter?: components['schemas']['Projects.UpdatePDFPropertyRequest']['config']['splitter']
        }
      }
    | {
        type: 'collection' | 'file_collection'
        config: UpdateCollectionPropertyConfig
      }
    | {
        type: 'reference'
        config: {
          entityLimit?: components['schemas']['Projects.UpdateReferencePropertyRequest']['config']['entity_limit']
        }
      }
    | {
        type: 'number'
        config?: components['schemas']['Projects.UpdateNumberPropertyRequest']['config']
      }
  )

const updatePropertyConfig = (params: UpdatePropertyParams) => {
  if (
    params.type === 'single_select' ||
    params.type === 'multi_select' ||
    params.type === 'user_select'
  ) {
    return {
      type: params.type,
      config: {
        default_option: params.config?.defaultOption,
        max_selected: params.config?.maxSelected,
        remove_options: params.config?.removeOptions ?? [],
        upsert_options: params.config?.upsertOptions ?? [],
      },
    }
  }

  if (params.type === 'pdf') {
    return { type: params.type, config: { splitter: params.config?.splitter ?? 'page_split' } }
  }

  if (params.type === 'collection') {
    return {
      type: params.type,
      config: {
        upsert_properties: params.config?.upsertCollectionProperties ?? [],
        remove_properties: params.config?.removeCollectionProperties ?? [],
      },
    }
  }

  if (params.type === 'reference') {
    return {
      type: params.type,
      config: {
        entity_limit: params.config?.entityLimit ?? 1,
      },
    }
  }

  if (params.type === 'number') {
    return {
      type: params.type,
      config: params.config || {
        format: 'auto',
      },
    }
  }

  return { type: params.type }
}

export const updateProperty = (
  workspaceId: string,
  projectId: string,
  propertyId: string,
  params: UpdatePropertyParams,
) => {
  return put<UpdatePropertyRequest, UpdatePropertyResponse, UpdatePropertyPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/properties/${propertyId}`,
    {
      name: params.name,
      description: params.description,
      inputs: params.inputs,
      tool: params.tool,
      is_grounded: params.isGrounded,
      ...updatePropertyConfig(params),
    },
  )
}
