<script setup lang="ts">
import LibraryDocument from '@/modules/Library/LibraryDocument.vue'
import { useLibraryDocument } from '@/modules/Library/useLibraryDocument'
import { useUploadLibraryFile } from '@/modules/Library/useUploadLibraryFile'
import FileTableEmptyStateIllustration from '@/modules/Workspaces/KnowledgeHub/Files/FileTableEmptyStateIllustration.svg'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

const workspace = useCurrentWorkspace()
const { onClickUpload } = useUploadLibraryFile()
const { onClickCreateDocument, isEditingLibraryDocument, onClose, onSaveDocument } =
  useLibraryDocument()

function onNewDocumentSave({ content, title }: { content: string; title: string }) {
  onSaveDocument({ content, title, workspaceId: workspace.value.id })
}
</script>

<template>
  <div class="flex h-full flex-col items-center justify-start">
    <FileTableEmptyStateIllustration />
    <div class="flex flex-col items-center justify-center gap-3">
      <h2 class="text-xl-18px-bold">No files yet</h2>
      <div class="text-center text-sm-12px-light text-text-subtle">
        Files in the Knowledge Hub can be used as context for AI models.<br />
        Drag and drop any PDF, CSV, or Word document here, or create your own markdown notes.
      </div>
      <div class="flex flex-row items-center justify-center gap-2">
        <DarwinButton
          variant="black"
          rounded
          size="sm"
          @click="onClickUpload"
        >
          <template #leading-icon>
            <IconSprite icon="upload" />
          </template>
          Upload file
        </DarwinButton>
        <DarwinButton
          variant="neutral"
          rounded
          size="sm"
          @click="onClickCreateDocument"
        >
          <template #leading-icon>
            <IconSprite icon="circle-plus-fill" />
          </template>
          Create note
        </DarwinButton>
      </div>
    </div>
    <LibraryDocument
      aria-label="Create new library document"
      :open="isEditingLibraryDocument"
      @close="onClose"
      @save="onNewDocumentSave"
    />
  </div>
</template>
