import { GooglePickerClosedError } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/errors'
import { useGoogleDriveConnection } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/useGoogleDriveConnection'
import type { Integration } from '@/modules/Workspaces/KnowledgeHub/Integrations/integrationTypes'
import { defineAsyncComponent } from 'vue'

export const googleDriveIntegration: Integration = {
  id: 'google_drive',
  name: 'Google Drive',
  description:
    'Experience superior search and use docs, sheets, slides, and other Drive files as context in your chats.',
  asyncComponent: defineAsyncComponent(() => import('./GoogleDriveConnectionDialog.vue')),
  icon: 'google-drive-logo',
  start(connectionId: string) {
    const googleDriveConnection = useGoogleDriveConnection()
    return googleDriveConnection.pick(connectionId).catch((e) => {
      if (e instanceof GooglePickerClosedError) return
      throw e
    })
  },
}
