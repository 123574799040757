<script setup lang="ts">
import { useLibraryStore, type LibraryItem } from '@/modules/Library/libraryStore'
import { useFileTableContext } from '@/modules/Workspaces/KnowledgeHub/Files/context'
import EmptyState from '@/modules/Workspaces/KnowledgeHub/Files/EmptyState.vue'
import FileTableEmptyState from '@/modules/Workspaces/KnowledgeHub/Files/FileTableEmptyState.vue'
import FileTableHeader from '@/modules/Workspaces/KnowledgeHub/Files/FileTableHeader.vue'
import FileRow from '@/modules/Workspaces/KnowledgeHub/Files/FileTableItemRow.vue'
import {
  documentFileType,
  getFileTypeName,
  type TableSorting,
} from '@/modules/Workspaces/KnowledgeHub/Files/library-item'
import { exhaustiveGuard } from '@/shared/utils/typeAssertions'
import { watchDebounced } from '@vueuse/core'
import { useFuse } from '@vueuse/integrations/useFuse.mjs'
import { computed, ref } from 'vue'

const props = defineProps<{
  search: string
}>()

const libraryStore = useLibraryStore()
const context = useFileTableContext()

const items = computed(() => libraryStore.libraryItems)

const filters = computed(() => {
  const owners = context.filterOwners.value
  const ownersFilter = (item: LibraryItem) => {
    return owners.length === 0 ? true : owners.includes(item.updatedBy)
  }

  const fileTypes = context.filterFileType.value
  const fileTypesFilter = (item: LibraryItem) => {
    if (fileTypes.length === 0) return true
    if (item.type === 'text') {
      return fileTypes.includes(documentFileType)
    }
    return fileTypes.some((type) => typeof type === 'string' && item.filename.endsWith(type))
  }

  return [ownersFilter, fileTypesFilter]
})

const filteredItems = computed(() => {
  return items.value.filter((item) => filters.value.every((filter) => filter(item)))
})

const sort = ref<TableSorting>(['createdAt', 'desc'])

/** When searchTerm is empty, we don't want to debounce, better UX */
const debounceTime = computed(() => (props.search ? 150 : 0))
const debouncedTerm = ref(props.search)

const fuse = useFuse(debouncedTerm, filteredItems, {
  matchAllWhenSearchEmpty: true,
  fuseOptions: { isCaseSensitive: false, keys: ['name'] },
})

const filteredBySearchTerm = computed(() => {
  const [sortField, sortDirection] = sort.value

  return fuse.results.value
    .map((r) => r.item)
    .sort((a, b) => {
      const left = sortDirection === 'desc' ? b : a
      const right = sortDirection === 'desc' ? a : b

      switch (sortField) {
        case 'filename':
          return left.name.localeCompare(right.name)
        case 'createdAt':
          return left.createdAt.localeCompare(right.createdAt)
        case 'updatedBy':
          return left.updatedBy.localeCompare(right.updatedBy)
        case 'fileType':
          return getFileTypeName(left).localeCompare(getFileTypeName(right))
        default:
          return exhaustiveGuard(sortField, `Unsupported sort field: ${sortField}`)
      }
    })
})

const hasNoSearchResults = computed(() => {
  return items.value.length > 0 && filteredBySearchTerm.value.length === 0
})

watchDebounced(
  () => props.search,
  (term) => (debouncedTerm.value = term),
  { immediate: true, debounce: debounceTime },
)
</script>

<template>
  <div class="flex size-full flex-col overflow-hidden pb-14">
    <!-- 👆 pb-14 is so that last row is not obscured by the action bar -->
    <template v-if="libraryStore.isLoaded">
      <FileTableEmptyState
        v-if="items.length === 0"
        class="mt-10"
      />
      <template v-else-if="hasNoSearchResults">
        <EmptyState>
          <template #title>No result found</template>
          <template #description>We could not find any file matching your search.</template>
        </EmptyState>
      </template>
      <template v-else>
        <FileTableHeader v-model:sort="sort" />
        <div
          class="overflow-auto scrollbar-thin"
          role="list"
        >
          <FileRow
            v-for="file in filteredBySearchTerm"
            :key="file.id"
            role="listitem"
            :aria-label="file.name"
            :item="file"
            :progress="libraryStore.uploadProgressMap[file.id]"
          />
        </div>
      </template>
    </template>
  </div>
</template>
