<script lang="ts" setup>
import {
  autoPlacement,
  autoUpdate,
  arrow as fuiArrow,
  hide,
  offset as offsetFn,
  size,
  useFloating,
  type AutoPlacementOptions,
  type OffsetOptions,
  type Placement,
} from '@floating-ui/vue'
import { computed, nextTick, ref, watchEffect, type HTMLAttributes, type StyleValue } from 'vue'
import { getPopoverTeleportTarget } from './utils/teleport'

/**
 * This component provides most of the positioning logic used to render the Figma Tooltip
 * component:
 * https://www.figma.com/file/1HfA941cU4A9RZxXHLmbpG/AGIDB---Design-System?type=design&node-id=199%3A5717&mode=dev
 *
 * Note that the Product, Design and other non-frontend people will refer to all floating
 * popups as tooltips. This does not align with the technical definition of a tooltip, which
 * has a number of specific requirements such as:
 * - It must appear after some trigger element receives focus or mouseover
 * - It must disappear after the trigger element loses focus or mouseout
 * - It must not contain any interactive elements, and must never receive focus
 * - It must close on escape
 * - ... and many more
 * see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tooltip_role
 *
 * If you need a true tooltip, you should not use this component directly, and should instead
 * use the `ToolTip` component. You should use this component when the popup should appear
 * in response to a change in some reactive state.
 */

export type PopoverProps = {
  open: boolean
  arrow?: boolean
  matchTargetWidth?: boolean
  /**
   * High level rules for how the popup should be placed.
   *
   * @see https://floating-ui.com/docs/autoPlacement
   */
  placement?: AutoPlacementOptions
  role?: 'dialog' | 'tooltip'
  offset?: number | OffsetOptions
  /**
   * Element reference or CSS selector of an element to anchor the tooltip to. If not provided, the tooltip
   * will be anchored to whatever element is passed into its `trigger` slot.
   */
  targetSelector?: string | null | HTMLElement
  /**
   * For accessibility, the ID of the element that describes the tooltip.
   */
  ariaLabelledby?: string
  disableTeleport?: boolean
  teleportTo?: string
  floatingZIndex?: number
  popoverContainerAttrs?: HTMLAttributes
}

const props = withDefaults(defineProps<PopoverProps>(), {
  placement: undefined,
  role: 'dialog',
  offset: 8,
  targetSelector: undefined,
  ariaLabelledby: undefined,
  matchTargetWidth: false,
  disableTeleport: false,
  teleportTo: undefined,
  floatingZIndex: undefined,
  popoverContainerAttrs: undefined,
})

const emit = defineEmits<{
  (e: 'mouseover:popover', event: MouseEvent): void
}>()

/** Target element - when hovered it will activate the tooltip */
const innerTarget = ref<HTMLElement | null>()

const target = ref<HTMLElement | null>(null)

watchEffect(async () => {
  if (innerTarget.value) {
    target.value = innerTarget.value
  } else if (!props.targetSelector) {
    target.value = null
  } else if (typeof props.targetSelector === 'string') {
    await nextTick() // Wait for the DOM to be updated
    target.value = document.querySelector<HTMLElement>(props.targetSelector)
  } else {
    target.value = props.targetSelector as HTMLElement
  }
})

/** Tooltip element that will appear when the target is hovered */
const content = ref<HTMLDivElement>()
const floatingArrow = ref<HTMLDivElement>()

const middleware = computed(() => {
  const offsetOptions: OffsetOptions =
    typeof props.offset === 'number'
      ? { alignmentAxis: -props.offset, crossAxis: 0, mainAxis: props.offset }
      : props.offset
  const middlewareArray = [offsetFn(offsetOptions)]

  if (props.placement) {
    middlewareArray.push(autoPlacement(props.placement))
  }

  if (props.arrow) {
    middlewareArray.push(fuiArrow({ element: floatingArrow }))
  }

  if (props.matchTargetWidth)
    middlewareArray.push(
      size({
        apply({ rects, elements }) {
          elements.floating.style.width = `${rects.reference.width}px`
        },
      }),
    )

  if (props.targetSelector && props.targetSelector instanceof HTMLElement) {
    const isDetached = !props.targetSelector.closest('body')
    if (isDetached) {
      // If we can't find the target element in the DOM, hide the tooltip.
      // Otherwise it will render in the top left corner of the page.
      middlewareArray.push(hide())
    }
  }

  return middlewareArray
})

const { floatingStyles, middlewareData } = useFloating(target, content, {
  middleware,
  whileElementsMounted: autoUpdate,
})

const ARROW_HEIGHT = 12
const chosenPlacement = computed(() => middlewareData.value.offset?.placement)
/**
 * Floating UI will decide where to place the tooltip based on the available space
 * and dimensions of each element. We only need to know where the tooltip is in relation
 * to the target element - top, bottom, left or right. With this information we can
 * work out where the arrow should be placed.
 */
type Direction = 'top' | 'bottom' | 'left' | 'right'
const PLACEMENT_MAP: Record<Placement, Direction> = {
  'top-start': 'top',
  'top-end': 'top',
  top: 'top',
  'bottom-start': 'bottom',
  'bottom-end': 'bottom',
  bottom: 'bottom',
  'left-start': 'left',
  'left-end': 'left',
  left: 'left',
  'right-start': 'right',
  'right-end': 'right',
  right: 'right',
}
/**
 * If the target is to the left/right of the target then floating-ui will calculate
 * the y position of the arrow. If the target is above/below the target then floating-ui
 * will calculate the x position of the arrow.
 */
const chosenDirection = computed(
  () => chosenPlacement.value && PLACEMENT_MAP[chosenPlacement.value],
)

const arrowStyles = computed<StyleValue>(() => {
  if (
    !props.arrow ||
    !middlewareData.value.arrow ||
    !floatingArrow.value ||
    !chosenDirection.value
  ) {
    return {}
  }

  const placement = middlewareData.value.offset?.placement
  if (!placement) {
    return {}
  }

  const { x, y } = middlewareData.value.arrow
  /**
   * Calculate the left position of the arrow.
   * - If the tooltip is above/below the target then floating-ui will have done this.
   * - If the tooltip is to the right of the target then the arrow is pretty much correct, we just need to adjust for padding
   * - If the tooltip is to the left of the target then we need to adjust for the width of the tooltip and padding
   */
  const left: string = (() => {
    if (x && ['top', 'bottom'].includes(chosenDirection.value)) {
      return `${x}px`
    }

    const xOffset = middlewareData.value.offset?.x ?? 0
    if (chosenDirection.value === 'left') {
      return `calc(100% + ${xOffset}px)`
    }

    return `${-xOffset / 2}px`
  })()

  /**
   * Calculate the top position of the arrow.
   * - If the tooltip is to the left/right of the target then floating-ui will have done this.
   * - If the tooltip is below the target then the arrow is pretty much correct, we just need to adjust for padding
   * - If the tooltip is above the target then we need to adjust for the height of the tooltip and padding
   */
  const top: string = (() => {
    if (y && ['left', 'right'].includes(chosenDirection.value)) {
      return `${y}px`
    }

    const yOffset = middlewareData.value.offset?.y ?? 0
    if (chosenDirection.value === 'top') {
      return `calc(100% + ${yOffset}px)`
    }

    return `${-yOffset / 2}px`
  })()

  return {
    position: 'absolute',
    left,
    top,
    height: `${ARROW_HEIGHT}px`,
    width: `${ARROW_HEIGHT}px`,
    transform: 'rotate(45deg)',
  }
})

/**
 * For the scale transition to look natural, the transform origin needs to be set
 * to where the tooltip is anchored to the target.
 */
const transitionRootStyles = computed(() => {
  if (!chosenPlacement.value) {
    return {}
  }

  /**
   * These origins will look a little off for the -start and -end placements when
   * there is an arrow, because technically the transform should originate from the
   * arrow tip. This is a good enough approximation for now.
   */
  const map: Record<Placement, string> = {
    'top-start': '0% 100%',
    'top-end': '100% 100%',
    top: '50% 100%',
    'bottom-start': '0% 0%',
    'bottom-end': '100% 0%',
    bottom: '50% 0%',
    'left-start': '100% 0%',
    'left-end': '100% 100%',
    left: '100% 50%',
    'right-start': '0% 0%',
    'right-end': '0% 100%',
    right: '0% 50%',
  }
  return {
    transformOrigin: map[chosenPlacement.value],
  }
})

const onMouseover = (event: MouseEvent) => {
  emit('mouseover:popover', event)
}

defineOptions({ inheritAttrs: false })

const injectedTeleportTarget = getPopoverTeleportTarget()
</script>

<template>
  <span
    v-if="!targetSelector"
    ref="innerTarget"
    v-bind="$attrs"
  >
    <slot name="trigger" />
  </span>
  <Teleport
    :to="teleportTo || injectedTeleportTarget || 'body'"
    :disabled="disableTeleport"
    :defer="!!(teleportTo || injectedTeleportTarget)"
  >
    <div
      ref="content"
      v-bind="popoverContainerAttrs"
      :style="{
        ...floatingStyles,
        zIndex: floatingZIndex,
        visibility: middlewareData.hide?.referenceHidden ? 'hidden' : 'visible',
      }"
      @mouseover="onMouseover"
    >
      <Transition
        enter-active-class="transition-all duration-250 ease-in-out"
        leave-active-class="transition-all duration-100 ease-in-out"
        enter-from-class="opacity-0 shadow-none scale-90"
        enter-to-class="opacity-100 shadow-md scale-100"
        leave-from-class="opacity-100 scale-100 shadow-md"
        leave-to-class="opacity-0 scale-90 shadow-none"
      >
        <div
          v-if="open && target"
          :style="transitionRootStyles"
          :role="role"
          :aria-labelledby="ariaLabelledby"
        >
          <div
            v-if="arrow"
            ref="floatingArrow"
            class="rounded-corner-2 border border-border bg-surface-popover-inverted"
            :style="arrowStyles"
          />
          <slot name="content" />
        </div>
      </Transition>
    </div>
  </Teleport>
</template>
