<script setup lang="ts">
import { deleteConnection } from '@/backend/deleteConnection'
import { useUser } from '@/modules/IdentityAndAccess/useUser'
import { gmailIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/Gmail/gmailIntegration'
import { googleDriveIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/googleDriveIntegration'
import IntegrationCard from '@/modules/Workspaces/KnowledgeHub/Integrations/IntegrationCard.vue'
import IntegrationConnectionRow from '@/modules/Workspaces/KnowledgeHub/Integrations/IntegrationConnectionRow.vue'
import { integrations } from '@/modules/Workspaces/KnowledgeHub/Integrations/integrations'
import type {
  Integration,
  PreviewIntegration,
} from '@/modules/Workspaces/KnowledgeHub/Integrations/integrationTypes'
import { outlookIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/Outlook/outlookIntegration'
import { sharepointIntegration } from '@/modules/Workspaces/KnowledgeHub/Integrations/SharePoint/sharepointIntegration'
import { useLazyIntegrationDialog } from '@/modules/Workspaces/KnowledgeHub/Integrations/useLazyIntegrationDialog'
import PageContainer from '@/modules/Workspaces/KnowledgeHub/Layout/PageContainer.vue'
import PageTitle from '@/modules/Workspaces/KnowledgeHub/Layout/PageTitle.vue'
import PageTitleBar from '@/modules/Workspaces/KnowledgeHub/Layout/PageTitleBar.vue'
import { requestedIntegrations } from '@/modules/Workspaces/KnowledgeHub/preferences'
import { useIntegrationPolling } from '@/modules/Workspaces/KnowledgeHub/useIntegrationPolling'
import { useWorkspaceMembers } from '@/modules/WorkspaceSettings/useWorkspaceMembers'
import { toast } from '@/shared/toast'
import { useAnalytics } from '@/sharedComposables/useAnalytics'
import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed, ref, toValue } from 'vue'
import { useIntegrationStore } from './useIntegrationStore'

type CardData = (Integration | PreviewIntegration) & {
  isPreview?: boolean
  /**
   * For real integrations, this is true when there's a connection.
   * For previews, it's true when the integration is requested.
   */
  isActive: boolean
  onClick: () => void
}

const userStore = useUser()
const { workspaceId } = useRouteParams()
const integrationStore = useIntegrationStore()
const workspaceMembersStore = useWorkspaceMembers()
const { captureAnalyticsEvent } = useAnalytics()
const { isDialogOpen, loadDialog, integrationDialog, onDialogMounted } = useLazyIntegrationDialog()

/**
 * When we configure a connection, we need to reload data to show connections.
 * In the meantime we should prevent users from restarting the configuration flow.
 */
const pendingConfigCompletion = ref<string[]>([])

useIntegrationPolling()

const connections = computed(() => {
  return integrationStore.connections.map((c) => ({
    ...c,
    user: workspaceMembersStore.getMember(c.userId),
    name: integrations[c.integrationId].name,
    icon: integrations[c.integrationId].icon,
  }))
})

/**
 * List of integrations to be displayed on the home page.
 * Manually ordered for fine control.
 */
const integrationCardList = computed<CardData[]>(() => [
  getRealIntegrationCard(googleDriveIntegration),
  getPreviewIntegrationCard(sharepointIntegration),
  getPreviewIntegrationCard(outlookIntegration),
  getPreviewIntegrationCard(gmailIntegration),
])

function getPreviewIntegrationCard(integration: PreviewIntegration): CardData {
  return {
    ...integration,
    isPreview: true,
    isActive: requestedIntegrations.value.includes(integration.id),
    onClick() {
      const isCaptured = captureAnalyticsEvent('Integration requested', {
        integration_id: integration.id,
        user_id: userStore.user?.id,
        user_email: userStore.user?.email,
        workspace_id: workspaceId.value,
      })

      if (isCaptured) {
        requestedIntegrations.value.push(integration.id)
      }
    },
  }
}

function getRealIntegrationCard(integration: Integration): CardData {
  return {
    ...integration,
    isActive: integrationStore.isConnected(integration.id),
    onClick: () => loadDialog(integration.asyncComponent),
  }
}

async function removeConnection(c: (typeof integrationStore.connections)[number]) {
  if (!workspaceId.value) return
  await deleteConnection({
    connectionId: c.id,
    integrationId: c.integrationId,
    workspaceId: workspaceId.value,
  })
  integrationStore.load()
}

function findIntegrationByConnectionId(connectionId: string): Integration | undefined {
  const integrationId = integrationStore.connections.find(
    (c) => c.id === connectionId,
  )?.integrationId
  if (integrationId) {
    return integrations[integrationId]
  }
}

async function startConnection(connectionId: string) {
  const integration = findIntegrationByConnectionId(connectionId)
  const pending = toValue(pendingConfigCompletion)
  if (!integration || pending.includes(connectionId)) {
    return
  }

  pending.push(connectionId)
  try {
    await integration.start(connectionId)
    integrationStore.load()
  } catch (e) {
    toast.error(e instanceof Error ? e.message : 'Failed to start connection')
  } finally {
    pending.splice(pending.indexOf(connectionId), 1)
  }
}
</script>

<template>
  <PageContainer class="px-16">
    <PageTitleBar class="mb-10">
      <template #title>
        <PageTitle :count="connections.length">Integrations</PageTitle>
      </template>
    </PageTitleBar>

    <!-- Wait until the first load, we have no loading state yet (will be added soon) -->
    <template v-if="integrationStore.isLoaded">
      <div
        v-if="connections.length"
        class="mb-12"
      >
        <h2 class="mt-10 text-md-13px-bold">Enabled</h2>
        <p class="text-sm-12px-light text-text-subtle">Manage your connected integrations</p>

        <IntegrationConnectionRow
          v-for="connection in connections"
          :key="connection.integrationId"
          :connection="connection"
          class="relative mt-6"
          :starting="pendingConfigCompletion.includes(connection.id)"
          @remove="removeConnection(connection)"
          @start="startConnection(connection.id)"
        />
      </div>

      <div>
        <template v-if="connections.length">
          <h2 class="text-md-13px-bold">Explore more</h2>
          <p class="text-sm-12px-light text-text-subtle">
            Find more integrations to connect to your account
          </p>
        </template>
        <div class="mx-auto mt-6 grid grid-cols-[repeat(auto-fill,minmax(275px,1fr))] gap-1">
          <IntegrationCard
            v-for="integration in integrationCardList"
            :key="integration.id"
            :name="integration.name"
            :description="integration.description ?? ''"
            :is-preview="integration.isPreview || false"
            :is-active="integration.isActive"
            :icon="integration.icon"
            @activate="integration.onClick"
          />
        </div>
      </div>
    </template>

    <component
      :is="integrationDialog"
      v-if="integrationDialog"
      :open="isDialogOpen"
      @close="isDialogOpen = false"
      @vue:mounted="onDialogMounted"
    />
  </PageContainer>
</template>
