import { PropertyType, ToolProvider } from '@/backend/types'
import { defaultToolConfig } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const o3MiniTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'o3 Mini',
  description:
    'o3-mini is OpenAI’s latest small reasoning model, optimized for coding, math, and science tasks. It delivers faster responses than o1-mini and can match or exceed o1’s accuracy with higher reasoning settings.',
  outputTypes: [
    PropertyType.text,
    PropertyType.single_select,
    PropertyType.multi_select,
    PropertyType.json,
    PropertyType.collection,
    PropertyType.url,
    PropertyType.reference,
    PropertyType.number,
  ],
  provider: ToolProvider.open_ai,
  isRateLimited: true,
  isNew: true,
  icon: 'company-openai',
}
