import { useStorage } from '@vueuse/core'

/**
 * This is used to control whether the Knowledge Hub intro modal is shown.
 *
 * ⚠️ The key is also used in the library spec e2e test to control the intro modal.
 * @see playwright/e2e/global-setup.ts
 */
export const showKnowledgeHubIntro = useStorage('showKnowledgeHubIntro', true)

export const requestedIntegrations = useStorage<string[]>('requestedIntegrations', [])
