import type { PropertyType } from '@/backend/types'
import { useBilling } from '@/modules/Billing/useBilling'
import { useLimitedAction } from '@/modules/Billing/useLimitedAction'
import type { Property } from '@/modules/Project/Properties/types'
import { serializeProperty, useProject } from '@/modules/Project/useProject'
import { useProperty } from '@/modules/Project/useProperty'
import { optimisticPropertyMap } from '@/modules/Project/utils'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { ANALYTICS_EVENT, useAnalytics } from '@/sharedComposables/useAnalytics'

export function useProjectActions() {
  const workspace = useCurrentWorkspace()
  const projectStore = useProject()
  const { addProperty } = useLimitedAction()
  const billingStore = useBilling()
  const propertyStore = useProperty()
  const { captureAnalyticsEvent } = useAnalytics()

  async function createProperty(type: PropertyType) {
    const projectId = projectStore.projectId
    const workspaceId = workspace.value.id
    if (!projectId || !workspaceId) {
      return
    }
    const optimisticId = crypto.randomUUID()
    const optimisticProperty: Property = {
      ...optimisticPropertyMap[type],
      id: optimisticId,
      slug: optimisticId,
    }
    projectStore.optimisticIds.push(optimisticId)
    projectStore.upsertProperty(optimisticProperty)
    if (projectStore.activeView) {
      projectStore.upsertView({
        ...projectStore.activeView.view,
        propertyIds: [...(projectStore.activeView.view.propertyIds ?? []), optimisticProperty.id],
      })
    }

    if (optimisticProperty.type === 'reference') {
      projectStore.selectedPropertyId = optimisticProperty.id
      projectStore.upsertProperty(optimisticProperty)
      /**
       * Reference properties can't be created on the backend until the
       * user has linked a reference project. So we return before sending
       * the BE request. The property will be created on the backend
       * from a different component.
       */
      return
    }

    const newProperty: Parameters<typeof addProperty>[2] =
      optimisticProperty.type === 'number'
        ? {
            name: optimisticProperty.name,
            type: optimisticProperty.type,
            tool: optimisticProperty.tool,
            isGrounded: optimisticProperty.isGrounded,
            description: optimisticProperty.description,
            inputIds: [],
            viewId: projectStore.activeView?.id ?? projectStore.mainView?.id,
            config: {
              format: 'auto',
            },
          }
        : {
            name: optimisticProperty.name,
            type: optimisticProperty.type,
            tool: optimisticProperty.tool,
            isGrounded: optimisticProperty.isGrounded,
            description: optimisticProperty.description,
            inputIds: [],
            viewId: projectStore.activeView?.id ?? projectStore.mainView?.id,
          }

    const result = await addProperty(workspaceId, projectId, newProperty)

    projectStore.removeProperty(optimisticId)
    if (projectStore.activeView) {
      projectStore.upsertView({
        ...projectStore.activeView.view,
        propertyIds: projectStore.activeView.view.propertyIds.filter((id) => id !== optimisticId),
      })
    }

    if (result.ok) {
      const newPropertyId = result.data.id
      if (
        // The websocket connection might have already added this property ID
        // to the view
        projectStore.activeView?.view.propertyIds &&
        !projectStore.activeView.view.propertyIds.includes(newPropertyId)
      ) {
        projectStore.activeView.view.propertyIds.push(newPropertyId)
      }

      if (billingStore.fieldUsage) {
        billingStore.fieldUsage.limitUsage += projectStore.mainView?.entities?.length ?? 0
      }

      const serializedProperty = serializeProperty(result.data)

      projectStore.upsertProperty(serializedProperty)
      projectStore.selectedPropertyId = newPropertyId
      propertyStore.reset()

      captureAnalyticsEvent(ANALYTICS_EVENT.PROPERTY_CREATED, {
        workspaceId: workspace.value.id,
        projectId: projectStore.projectId,
        propertyId: newPropertyId,
      })

      return serializedProperty
    }
  }

  return {
    createProperty,
  }
}
