import { useDebounce } from '@vueuse/core'
import { computed, ref, type Ref } from 'vue'

/**
 * Our PopOver component is intentionally unopinionated about how and when its
 * content is displayed. However, in many cases we want the behaviour to be
 * typical of a tooltip, i.e. to show the content when the user hovers over a
 * trigger element, and hide it when the user moves the cursor away.
 *
 * This composable provides state and handlers to attach to the <PopOver> and
 * its trigger/content elements to achieve this behaviour.
 */
export const useTooltip = ({ disabled }: { disabled?: Ref<boolean> } = {}) => {
  /**
   * Private open value, not used outside of this composable. The value is immediately
   * set to true/false whenever something triggers the tooltip to open/close.
   */
  const _isOpen = ref(false)

  const setOpen = () => (_isOpen.value = true)
  const setClosed = () => (_isOpen.value = false)

  const debounceMs = computed(() => (_isOpen.value ? 300 : 100))
  const debouncedValue = computed(() => debounceMs.value)
  const debouncedIsOpen = useDebounce(_isOpen, debouncedValue)

  /**
   * Public open value, debounced to prevent flickering or accidental closing when
   * the user moves the cursor between the trigger and the popover. Also takes the
   * disabled state into account.
   */
  const isOpen = computed(() => debouncedIsOpen.value && !disabled?.value)

  const trigger = {
    onMouseOver: setOpen,
    onMouseLeave: setClosed,
    onFocusIn: setOpen,
    onFocusOut: setClosed,
    onClick: setClosed,
  }

  const popover = {
    onMouseOver: setOpen,
    onMouseLeave: setClosed,
  }

  return {
    isOpen,
    trigger,
    popover,
    debounceMs,
  }
}
