import { ToolProvider } from '@/backend/types'
import { defaultToolConfig, geminiOutputTypes } from '@/modules/Project/Tools/tool-config-presets'
import type { ToolRegistryItem } from '@/modules/Project/Tools/tool-types'

export const gemini20FlashLiteTool: ToolRegistryItem = {
  ...defaultToolConfig,
  label: 'Gemini 2.0 Flash Lite',
  description: 'Gemini 2.0 Flash Lite is a lightweight version of Gemini 2.0 Flash.',
  outputTypes: geminiOutputTypes,
  provider: ToolProvider.google_ai,
  icon: 'company-google',
  isNew: true,
}
