<script setup lang="ts">
import SidebarToggle from '@/sharedComponents/SidebarToggle.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'

defineProps<{ caseId: string }>()

const { isOpen } = useSidebar()
</script>

<template>
  <div class="relative h-2">
    <div
      class="absolute left-0 z-10 transition duration-300 ease-in-out-quint"
      :class="
        isOpen ? 'pointer-events-none translate-x-[-8px] opacity-0' : 'translate-x-0 opacity-100'
      "
    >
      <SidebarToggle :disable-tooltip="isOpen" />
    </div>
  </div>
</template>
