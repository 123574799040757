import { startConnection } from '@/backend/startConnection'
import {
  GooglePickerClosedError,
  GooglePickerTimeoutError,
} from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/errors'
import type { GoogleDrivePickedData } from '@/modules/Workspaces/KnowledgeHub/Integrations/GoogleDrive/types'
import { useCurrentWorkspace } from '@/modules/Workspaces/useCurrentWorkspace'
import { invariant } from '@/shared/utils/typeAssertions'
import { isObject } from '@vueuse/core'

type GoogleDriveErrorResponse = {
  error: {
    code: number
    message: string
    errors: Array<{
      message: string
      domain: string
      reason: string
      location: string
      locationType: string
    }>
    status: string
  }
}

const API_KEY = import.meta.env.VITE_GOOGLE_API_KEY
const APP_ID = import.meta.env.VITE_GOOGLE_APP_ID

export function useGoogleDriveConnection() {
  const googleReady = loadGoogleApis()

  const pick = async (connectionId: string) => {
    await googleReady
    return pickFiles(connectionId)
  }

  return { pick }
}

/**
 * Starts a connection with Google Drive on Go backend.
 *
 * @throws If we don't get a token from the first roundtrip
 * @throws If we fail to pick files from Google Drive picker
 */
async function pickFiles(connectionId: string) {
  const currentWorkspace = useCurrentWorkspace()
  const startParams: Parameters<typeof startConnection>[0] = {
    connectionId: connectionId,
    integrationId: 'google_drive',
    integratorConfig: {},
    workspaceId: currentWorkspace.value.id,
  }

  // First part of the flow is to get a token for Google Drive picker.
  // We get it by trying to start connection on Go backend without providing any files or folders.
  // Expected API response is a failure, but with a token in the response.
  const tokenResponse = await startConnection(startParams)
  invariant(!tokenResponse.ok, 'Failed to start connection, please try again.')

  // Second part of the flow is to pick files from Google Drive picker.
  // We use the token to authenticate with Google Drive picker.
  try {
    await assertTokenValidity(tokenResponse.error.code)
  } catch (error) {
    const msg = error instanceof Error ? error.message : 'Unknown error'
    throw new Error('Failed to connect to Google Drive: ' + msg)
  }

  const data = await createPicker(tokenResponse.error.code)
  const folders = data.docs.filter((d) => d.type === 'folder').map((d) => d.id)
  const files = data.docs.filter((d) => d.type !== 'folder').map((d) => d.id)

  // Third part of the flow is to start connection on Go backend with picked files and folders.
  // This time around we expect a success response.
  const startResponse = await startConnection({
    ...startParams,
    integratorConfig: { files, folders },
  })

  invariant(startResponse.ok, 'Failed to pick files from Google Drive')
}

async function loadGoogleApis() {
  const gapiScriptUrl = 'https://apis.google.com/js/api.js'
  const apiScript = document.querySelector(`script[src="${gapiScriptUrl}"]`)
  if (apiScript) {
    return
  }

  await loadExternalScript(gapiScriptUrl)
  gapi.load('client:picker', async () => {
    await gapi.client.load('https://www.googleapis.com/discovery/v1/apis/drive/v3/rest')
  })
  await loadExternalScript('https://accounts.google.com/gsi/client')
}

function createPicker(accessToken: string): Promise<GoogleDrivePickedData> {
  const view = new google.picker.DocsView().setIncludeFolders(true).setSelectFolderEnabled(true)

  return new Promise((resolve, reject) => {
    /**
     * This is a workaround for Google Drive picker which, when given an invalid token,
     * shows an unclosable modal. Their API also doesn't give a way to detect an auth error.
     *
     * We set a timeout here to make sure the picker doesn't hang indefinitely.
     * If we get to a picker callback, we clear the timeout as it means the picker is working.
     * If the picker times out, we assume that something caused the picker initialization to fail.
     */
    let pickerTimeout: number | undefined = undefined

    const pickerBuilder = new google.picker.PickerBuilder()
      .enableFeature(google.picker.Feature.NAV_HIDDEN)
      .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      .setDeveloperKey(API_KEY)
      .setAppId(APP_ID)
      .setOAuthToken(accessToken)
      .addView(view)
      .addView(new google.picker.DocsUploadView())
      .setCallback((data) => {
        clearTimeout(pickerTimeout)
        switch (data.action) {
          case 'picked': {
            const docs = data.docs || []
            resolve({ docs })
            break
          }
          case 'cancel':
            reject(new GooglePickerClosedError())
            break
          case 'error':
            reject(new Error('Failed to pick files', { cause: 'googlePickerError' }))
            break
          case 'loaded':
            break
          default:
            reject(new Error('Unknown error', { cause: 'googlePickerUnknownError' }))
            break
        }
      })
      .build()

    pickerBuilder.setVisible(true)

    pickerTimeout = window.setTimeout(() => {
      pickerBuilder.dispose()
      reject(new GooglePickerTimeoutError())
    }, 4000)
  })
}

function loadExternalScript(src: string) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.setAttribute('async', 'true')
    script.setAttribute('defer', 'true')

    script.onload = () => resolve(script)
    script.onerror = (e) => reject(e)

    document.body.appendChild(script)
  })
}

/**
 * Tests if the given OAuth token is valid by trying to access Google Drive API.
 *
 * This is a workaround for Google Drive picker which, when given an invalid token,
 * shows an unclosable modal. Their API also doesn't give a way to detect an auth error.
 *
 * This could also be any other API call that requires a valid OAuth token, not just Google Drive.
 */
function assertTokenValidity(token: string) {
  return new Promise((resolve, reject) => {
    const initialToken = gapi.client.getToken()
    gapi.client.setToken({ access_token: token })
    gapi.client
      .request({
        path: 'https://www.googleapis.com/drive/v3/files',
        method: 'GET',
        params: { pageSize: 1 },
      })
      .execute((response) => {
        gapi.client.setToken(initialToken)
        let msg = ''
        if (isGoogleDriveErrorResponse(response)) {
          msg = response.error.errors.at(0)?.message || 'Unknown error'
          reject(new Error(msg))
        } else {
          resolve(true)
        }
      })
  })
}

/**
 * Checks if the given response looks like a Google Drive error response.
 */
function isGoogleDriveErrorResponse(response: unknown): response is GoogleDriveErrorResponse {
  return (
    isObject(response) &&
    'error' in response &&
    isObject(response.error) &&
    'code' in response.error &&
    'message' in response.error &&
    'errors' in response.error &&
    Array.isArray(response.error.errors) &&
    'status' in response.error
  )
}
