import { SUPPORTED_MIME_TYPES, type PropertyType } from '@/backend/types'
import { computed, toValue, type MaybeRefOrGetter } from 'vue'

const xlsxMimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

/**
 * Returns all MIME types that are supported by the application.
 *
 * This composable can sometimes seem kind of useless, because it might
 * return the same array everywhere, so it could simply be a constant.
 *
 * However, we often add MIME types behind feature flags. When
 * we do so it's useful to only need to update one place (this composable).
 *
 * Xlsx only works when used with a page splitter type at the moment (2025-01-30).
 * Project fields (not library or chat) can pass field as an argument to indicate
 * that they can accept Xlsx.
 */
export const useSupportedMimeTypes = (field?: MaybeRefOrGetter<{ type: PropertyType }>) => {
  return computed<string[]>(() => {
    const fieldType = field ? toValue(field)?.type : undefined

    switch (fieldType) {
      case 'file_collection':
        // Xlsx only works when used with a page splitter type at the moment
        return [...SUPPORTED_MIME_TYPES, xlsxMimeType]
      default:
        return SUPPORTED_MIME_TYPES
    }
  })
}
