import { post } from './client'
import type { BackendMeta, NumberPropertyConfigRequest, PropertyType } from './types'

export type AddPropertyMeta = BackendMeta<
  '/api/workspaces/{workspace_id}/projects/{project_id}/properties',
  'post'
>

export type AddPropertyPath = AddPropertyMeta['path']
export type AddPropertyRequest = Omit<AddPropertyMeta['body'], 'type'> & {
  type: Exclude<AddPropertyMeta['body']['type'], 'integration'>
}
export type AddPropertyResponse = AddPropertyMeta['successResponse']

type BaseAddPropertyParams = {
  name: string
  tool: AddPropertyRequest['tool']
  isGrounded: AddPropertyRequest['is_grounded']
  description: AddPropertyRequest['description']
  inputIds: AddPropertyRequest['input_ids']
  viewId?: AddPropertyRequest['default_view_id']
}

type AddPropertyParams = BaseAddPropertyParams &
  (
    | {
        type: 'number'
        config: NumberPropertyConfigRequest
      }
    | {
        type: 'reference'
        config: { entityLimit?: number; projectId: string }
      }
    | { type: Exclude<PropertyType, 'number' | 'reference'> }
  )

export const addPropertyConfig = (params: AddPropertyParams) => {
  if (
    params.type === 'single_select' ||
    params.type === 'multi_select' ||
    params.type === 'user_select'
  ) {
    return { type: params.type, config: { options: [] } }
  }

  if (params.type === 'pdf') {
    return { type: params.type, config: { splitter: 'page_split' as const } }
  }

  if (params.type === 'reference') {
    if (!params.config) {
      throw new Error('projectId is required for reference property')
    }
    return {
      type: params.type,
      config: {
        entity_limit: params.config.entityLimit || 1,
        project_id: params.config.projectId,
      },
    }
  }

  if (params.type === 'number') {
    return {
      type: params.type,
      config: params.config,
    }
  }

  return { type: params.type }
}

export const addProperty = (workspaceId: string, projectId: string, params: AddPropertyParams) =>
  post<AddPropertyRequest, AddPropertyResponse, AddPropertyPath>(
    `/api/workspaces/${workspaceId}/projects/${projectId}/properties`,
    {
      name: params.name,
      tool: params.tool,
      is_grounded: params.isGrounded,
      description: params.description,
      input_ids: params.inputIds,
      default_view_id: params.viewId,
      ...addPropertyConfig(params),
    },
  )
