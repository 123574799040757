import { escapeRegExp } from '@/shared/utils/regex'
import type { NumberPropertyFormat } from '../../Properties/types'
import type { FieldPreview } from '../../types/fieldPreview'
import type { Field } from '../types'

export const DEFAULT_FORMAT: NumberPropertyFormat = {
  decimalPlaces: 'auto',
  thousandsSeparator: false,
  negativeFormat: 'minus',
  rightAlign: true,
}

/**
 * For a given V7 Go number format, return the options to pass to the Intl.NumberFormat
 * constructor.
 */
export function getNumberFormatterOptions(format: NumberPropertyFormat): Intl.NumberFormatOptions {
  return {
    minimumFractionDigits: format.decimalPlaces === 'auto' ? undefined : format.decimalPlaces,
    maximumFractionDigits: format.decimalPlaces === 'auto' ? undefined : format.decimalPlaces,
    useGrouping: format.thousandsSeparator,
    signDisplay: format.negativeFormat === 'minus' ? 'auto' : 'never',
  }
}

/**
 * Returns the position where a number field's unit should be displayed, e.g.
 * - $100 - 'pre'
 * - 100€ - 'post'
 * - 100 - null
 */
function getNumberFieldUnitPosition(
  field: Field<'number'> | FieldPreview<'number'>,
): 'pre' | 'post' | null {
  if (field.toolValueUpdatedBy) {
    if (!field.toolValueUnit) {
      return null
    }

    if (!field.toolValueRaw) {
      return 'post'
    }

    const startsWithRegex = new RegExp(`^-?${escapeRegExp(field.toolValueUnit)}`)
    return field.toolValueRaw.match(startsWithRegex) ? 'pre' : 'post'
  }

  if (!field.manualValueUnit || !field.manualValueUnit) {
    return null
  }

  if (!field.manualValueRaw) {
    return 'post'
  }

  const startsWithRegex = new RegExp(`^-?${escapeRegExp(field.manualValueUnit)}`)
  const isPrefix = field.manualValueRaw.match(startsWithRegex)
  return isPrefix ? 'pre' : 'post'
}

/**
 * For a given number field, returns the unit that should be showed to the user, along
 * with its position relative to the number (e.g. 'pre'/'post').
 */
function getUnit(field: Field<'number'> | FieldPreview<'number'>): {
  text: string
  position: 'pre' | 'post'
} | null {
  const position = getNumberFieldUnitPosition(field)
  if (!position) {
    return null
  }

  if (field.toolValueUpdatedBy) {
    if (!field.toolValueUnit) {
      return null
    }
    return {
      text: field.toolValueUnit,
      position,
    }
  }

  if (!field.manualValueUnit) {
    return null
  }

  return {
    text: field.manualValueUnit,
    position,
  }
}

/** Adds a number field's unit to a number string, according to the unit's position. */
export function addUnit(
  numberString: string,
  field: Field<'number'> | FieldPreview<'number'>,
): string {
  const unit = getUnit(field)
  if (!unit) {
    return numberString
  }

  if (unit.position === 'pre') {
    if (numberString.startsWith('-')) {
      const numberWithoutNegative = numberString.slice(1)
      return `-${unit.text}${numberWithoutNegative}`
    }

    return `${unit.text}${numberString}`
  }

  const noSpace = unit.text === '%' || unit.text.startsWith('°')
  return `${numberString}${noSpace ? '' : ' '}${unit.text}`
}
