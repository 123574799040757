<script setup lang="ts">
import type { PropertyType } from '@/backend/types'
import IconSprite from '@/uiKit/IconSprite.vue'
import ToolTip from '@/uiKit/ToolTip.vue'

import { FIELD_TYPES_LABELS } from '@/modules/WorkspaceSettings/propertyConfig'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import Menu from '@/uiKit/Menu'
import PopOver from '@/uiKit/PopOver.vue'
import { computed, ref } from 'vue'
import { useProjects } from '../Projects/useProjects'
import { useWelcomeTour } from '../WelcomeTour/useWelcomeTour'
import { TYPE_ICON } from './icons'
import PropertyTypeTooltip from './PropertyTypeTooltip.vue'
import { useFieldTypeOptions } from './useFieldTypeOptions'

const props = defineProps<{
  projectId: string | null
}>()

const emit = defineEmits<{
  (e: 'create-property', type: PropertyType): void
}>()

const projectsStore = useProjects()
const welcomeTour = useWelcomeTour()
const menuIsOpen = ref(false)
const project = computed(() =>
  props.projectId ? projectsStore.getProjectById(props.projectId) : null,
)

const fieldTypesOptions = useFieldTypeOptions({
  hasParentProperty: computed(() => !!project.value?.parentProperty),
})

const onSelect = (type: PropertyType) => {
  menuIsOpen.value = false
  emit('create-property', type)
}

const onOpenChange = (isOpen: boolean) => {
  menuIsOpen.value = isOpen
}
</script>

<template>
  <Menu.Root
    v-slot="{ getTriggerProps }"
    :positioning="{ placement: 'bottom-start', offset: { mainAxis: 0, crossAxis: 1 } }"
    :auto-placement="{ allowedPlacements: ['bottom-start', 'bottom-end'] }"
    class="group relative min-w-[32px] select-none items-center"
    :disable-close-on-click-outside="welcomeTour.status === 'IN_PROGRESS'"
    close-on-select
    @change:open="onOpenChange"
  >
    <ToolTip
      title="Add property"
      :placement="{ allowedPlacements: ['top-end'] }"
    >
      <button
        v-bind="{ ...getTriggerProps(), ...$attrs }"
        class="h-[31px] w-8"
        aria-label="Add a property"
      >
        <IconSprite
          id="add-property"
          icon="plus"
          size="sm"
          class="size-full text-icon-subtle"
          data-test="add-property"
        />
      </button>
    </ToolTip>
    <Menu.Content
      :items="fieldTypesOptions.map((t) => ({ id: t.id, data: t.id }))"
      class="min-w-56"
      data-test="add-property-menu"
    >
      <Menu.Item
        v-for="item in fieldTypesOptions"
        :key="item.id"
        role="menuitem"
        :label="FIELD_TYPES_LABELS[item.id]"
        :icon="TYPE_ICON[item.id]"
        @select="onSelect(item.id)"
      >
        <template #prefix="{ active, id }">
          <PopOver
            v-if="welcomeTour.status !== 'IN_PROGRESS'"
            :open="active"
            :target-selector="`#${id}`"
            :placement="{ allowedPlacements: ['left-start', 'right-start'] }"
            :popover-container-attrs="{ class: 'pointer-events-none' }"
          >
            <!-- 👆 pointer-events-none here because the popover intercepts events in CI -->
            <template #content>
              <PropertyTypeTooltip :type="item.id" />
            </template>
          </PopOver>
        </template>
        <template
          v-if="item.id === 'number'"
          #suffix
        >
          <BadgeItem
            label="New"
            size="xs"
            variant="blue"
          />
        </template>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
