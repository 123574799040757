<script setup lang="ts">
import AppHeader from '@/sharedComponents/AppHeader.vue'
import { useSidebar } from '@/sharedComponents/useSidebar'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'

defineProps<{ caseId: string }>()

const { isOpen } = useSidebar()
</script>

<template>
  <AppHeader>
    <template #crumbs>
      <DarwinButton
        size="sm"
        variant="transparent"
        class="transition-transform duration-300 ease-in-out-quint"
        :class="isOpen ? 'translate-x-0' : 'translate-x-8'"
        :to="{ name: 'WorkspaceCases' }"
      >
        <template #leading-icon>
          <IconSprite icon="chevron-left" />
        </template>
        Back home
      </DarwinButton>
    </template>
    <div>Case {{ caseId }} header</div>
    <template #actions>
      <DarwinButton
        size="sm"
        variant="transparent"
      >
        <template #leading-icon>
          <IconSprite icon="upload" />
        </template>
        Export
        <template #trailing-icon>
          <IconSprite icon="chevron-bottom" />
        </template>
      </DarwinButton>
    </template>
  </AppHeader>
</template>
