<script lang="ts">
export type ProjectWithCoverImage = Project & { coverImageUrl: string | null }

export type ProjectOrIntegration =
  | ({
      itemType: 'project'
      projectId: string
    } & ProjectWithCoverImage)
  | ({
      itemType: 'integration'
      projectId: string
    } & StartedIntegration)
</script>

<script setup lang="ts">
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import Menu from '@/uiKit/Menu'
import SwitchButton from '@/uiKit/SwitchButton.vue'
import { computed, ref } from 'vue'
import { PROJECT_NAME_FALLBACK } from '../Project/constants'
import { getCoverImageUrl } from '../Project/utils'
import ObjectURLImage from '../Projects/ObjectURLImage.vue'
import { useProjects, type Project } from '../Projects/useProjects'
import {
  useStartedIntegrations,
  type StartedIntegration,
} from '../Workspaces/KnowledgeHub/Integrations/useStartedIntegrations'

const props = defineProps<{
  value: ProjectOrIntegration[]
}>()

defineEmits<{
  (e: 'select:item', item: ProjectOrIntegration): void
}>()

const projectsStore = useProjects()

const projects = computed<ProjectWithCoverImage[]>(() =>
  projectsStore.projects.map((p) => ({
    ...p,
    coverImageUrl: getCoverImageUrl(p, 'low'),
  })),
)

const getDisabledState = (projectId: string) => {
  return props.value.length === 1 && props.value[0].id !== projectId
}

const startedIntegrations = useStartedIntegrations()

const filteredProjects = ref<ProjectWithCoverImage[]>([])
</script>

<template>
  <Menu.Root v-slot="{ getTriggerProps }">
    <DarwinButton
      size="md"
      variant="neutral"
      rounded
      type="button"
      v-bind="{ ...getTriggerProps(), ...$attrs }"
    >
      <template #leading-icon>
        <IconSprite icon="api" />
      </template>
      Source
    </DarwinButton>
    <Menu.Content class="go-scrollbar max-h-96 min-w-56 overflow-y-auto overflow-x-hidden">
      <Menu.Search
        :items="projects"
        key-or-predicate="name"
        @change="filteredProjects = $event"
      />
      <Menu.GroupTitle label="Projects" />
      <Menu.Item
        v-for="project in filteredProjects"
        :key="project.id"
        :disabled="getDisabledState(project.id)"
        @select="$emit('select:item', { itemType: 'project', ...project, projectId: project.id })"
      >
        <div class="flex items-center gap-1">
          <SwitchButton
            tabindex="-1"
            :checked="value.some((item) => item.id === project.id)"
            :disabled="getDisabledState(project.id)"
          />
          <ObjectURLImage
            v-if="project.coverImageUrl"
            class="size-4 rounded-corner-4 object-cover"
            :url="project.coverImageUrl"
            :loading="false"
          />
          <IconSprite
            v-else
            icon="table"
            size="sm"
            class="text-icon-subtlest"
          />
          <div class="ml-1">
            {{ project.name || PROJECT_NAME_FALLBACK }}
          </div>
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.GroupTitle label="Integrations" />
      <Menu.Item
        v-for="startedIntegration in startedIntegrations"
        :key="startedIntegration.id"
        @select="
          $emit('select:item', {
            itemType: 'integration',
            ...startedIntegration,
            projectId: startedIntegration.projectId,
          })
        "
      >
        <div class="flex items-center gap-1">
          <SwitchButton
            tabindex="-1"
            :checked="value.some((item) => item.id === startedIntegration.id)"
            :disabled="getDisabledState(startedIntegration.id)"
          />
          <IconSprite
            :icon="startedIntegration.integration.icon"
            size="sm"
            class="mx-0.5"
          />
          <div>{{ startedIntegration.integration.name }}</div>
        </div>
      </Menu.Item>
    </Menu.Content>
  </Menu.Root>
</template>
