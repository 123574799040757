import { useRouteParams } from '@/sharedComposables/useRouteParams'
import { computed } from 'vue'
import { useRouteTrace } from '../routing/useRouteTrace'

/**
 * Gets the ID of the 'root' project, i.e. the curren't project's top-level
 * ancestor (or the current project itself if it has no parent).
 *
 * This isn't guaranteed to be correct, as the route trace might not be
 * present, in which case the parent project ID or current project ID are
 * our best guesses. A more reliable way to get the ID would be to recursively
 * fetch the project's parent until we reach the root project, but this
 * could be slow, and the trace is good enough for now.
 *
 * Ultimately we need a BE API to return this information.
 */
export const useRootProjectId = () => {
  const { currentTrace } = useRouteTrace()
  const { parentProjectId, projectId } = useRouteParams()

  return computed(
    () => currentTrace.value[0]?.projectId || parentProjectId.value || projectId.value,
  )
}
