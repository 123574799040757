<script setup lang="ts">
import { PropertyType, type SingleSelectPropertyConfig } from '@/backend/types'
import type { Property } from '@/modules/Project/Properties/types'
import type { PartialRecord } from '@/shared/types'
import { assertIsNotNullOrUndefined } from '@/shared/utils/typeAssertions'
import { isSelectField } from '@/shared/utils/typeGuards'
import BadgeItem from '@/uiKit/BadgeItem.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import ListMenu from '@/uiKit/ListMenu.vue'
import ListMenuCheckboxItem from '@/uiKit/ListMenuCheckboxItem.vue'
import ListMenuItem from '@/uiKit/ListMenuItem.vue'
// eslint-disable-next-line no-restricted-imports
import PopupMenu from '@/uiKit/PopupMenu.vue'
import type { AutoPlacementOptions } from '@floating-ui/core'
import type { OffsetOptions } from '@floating-ui/vue'
import { computed, onBeforeUnmount, ref } from 'vue'
import { getMemberName, useWorkspaceMembers } from '../WorkspaceSettings/useWorkspaceMembers'
import { TYPE_ICON } from './icons'
import type { Entity } from './useProject'
import { useProject } from './useProject'
/**
 * Renders a popup menu that allows the user to bulk update fields
 * for a given list of entities
 */

const props = defineProps<{
  /** Indicates where the popup can be placed relative to its trigger */
  placement?: AutoPlacementOptions
  /** Used to adjust the position of the popup relative to its trigger */
  offset?: OffsetOptions
  /** Optional CSS selector to teleport the popup to */
  teleportTo?: string
  /** Entities that will be affected by the bulk updates */
  entities: Entity[]
  /** Properties that will be affected by the bulk update */
  properties: Property<'multi_select' | 'single_select' | 'user_select'>[]
}>()

const emit = defineEmits<{
  (e: 'setValue', propertyId: string, value: SelectValues): void
}>()

type SelectValues = Partial<Record<Entity['id'], string[] | null>>

const workspaceMemberStore = useWorkspaceMembers()
const projectStore = useProject()

type SelectItem = {
  id: string
  data: {
    id: string
    name: string
    options: Array<{
      id: string
      data: SingleSelectPropertyConfig['options'][number] & { name: string }
    }>
    type: PropertyType
  }
}

/** All of the select properties on the current project */
const selectProperties = computed(() =>
  props.properties.map<SelectItem>((property) => {
    const options = property.config.options.map((o) => {
      const matchingWorkspaceMember = workspaceMemberStore.workspaceMembers.find(
        (m) => m.id === o.value,
      )
      const optionName =
        property.type === PropertyType.user_select && matchingWorkspaceMember
          ? getMemberName(matchingWorkspaceMember)
          : o.value

      return {
        id: o.value,
        data: {
          ...o,
          name: optionName,
        },
      }
    })

    return {
      id: property.id,
      data: {
        id: property.id,
        name: property.name,
        type: property.type,
        options: options ?? [],
      },
    }
  }),
)

/** ID of the property that is selected */
const activeSelectId = ref<string | null>(null)

const activeSelectProperty = computed(() => {
  if (!activeSelectId.value) {
    return null
  }

  const property = selectProperties.value.find((p) => p.id === activeSelectId.value)
  if (!property) {
    return null
  }

  return { ...property.data, currentValues: [] }
})

/** Maps entities to the original values they had */
const originalValues = ref<Partial<Record<Entity['id'], string[] | null>>>({})
/** Maps entities to the values they have after a user has made changes */
const newValues = ref<Partial<Record<Entity['id'], string[] | null>>>({})

/**
 * Maps each option value to the number of entities that have that value selected
 */
const countByValue = computed(() =>
  Object.values(newValues.value).reduce<Partial<Record<string, number>>>((acc, value) => {
    const values = value ?? []
    values.forEach((v) => {
      acc[v] = (acc[v] ?? 0) + 1
    })
    return acc
  }, {}),
)

/**
 * Maps each option value to a boolean indicating whether all selected entities have
 * - True if all selected entities have this value selected
 * - False if none of the selected entities have this value selected
 * - 'indeterminate' if some of the selected entities have this value selected
 */
const optionStatus = computed<Partial<Record<string, boolean | 'indeterminate'>>>(() =>
  Object.entries(countByValue.value).reduce<Partial<Record<string, boolean | 'indeterminate'>>>(
    (acc, [value, count]) => {
      if (count === props.entities.length) {
        acc[value] = true
      } else if (count === 0) {
        acc[value] = false
      } else {
        acc[value] = 'indeterminate'
      }
      return acc
    },
    {},
  ),
)

/** Handler for when the user clicks a select property */
const onClickSelectProperty = (item: SelectItem['data']) => {
  activeSelectId.value = item.id

  // Map each entity to its manual value
  const manualValueMap = props.entities.reduce<PartialRecord<Entity['id'], string[] | null>>(
    (acc, entity) => {
      assertIsNotNullOrUndefined(activeSelectId.value, 'activeSelectId is undefined')
      const field = entity.fields.get(activeSelectId.value)
      if (!field || !isSelectField(field)) {
        return acc
      }

      return {
        ...acc,
        [entity.id]: field.manualValue,
      }
    },
    {},
  )

  // Store the original values of the entities so we can revert if needed
  originalValues.value = { ...manualValueMap }
  newValues.value = { ...manualValueMap }
}

const isDirty = ref(false)
/**
 * Clear the active select property, changing the menu from rendering a list of options for a
 * given property, to rendering a list of select properties.
 */
const clearSelectProperty = () => {
  props.entities.forEach((entity) => {
    if (!activeSelectId.value) {
      return
    }

    const field = entity.fields.get(activeSelectId.value)
    const originalValue = originalValues.value[entity.id]
    if (!field || originalValue === undefined || !isSelectField(field)) {
      return
    }

    projectStore.updateField({ ...field, manualValue: originalValue })
  })
  activeSelectId.value = null
  isDirty.value = false
}

/**
 * Handler for when the user clicks an option in the select property menu.
 */
const onClickOption = (option: SelectItem['data']['options'][number]['data']) => {
  isDirty.value = true
  const action = [false, 'indeterminate'].includes(optionStatus.value[option.value] ?? false)
    ? 'add'
    : 'remove'

  if (!activeSelectProperty.value) {
    return
  }

  props.entities.forEach((entity) => {
    if (!activeSelectProperty.value) {
      return
    }

    const field = entity.fields.get(activeSelectProperty.value.id)
    if (!field) {
      return
    }
    if (!isSelectField(field)) {
      return
    }

    let manualValue = newValues.value[entity.id] ?? []

    if (action === 'remove') {
      manualValue = manualValue.filter((v) => v !== option.value)
    } else if (!manualValue.includes(option.value)) {
      if (activeSelectProperty.value.type === PropertyType.single_select) {
        manualValue = [option.value]
      } else {
        manualValue = [...manualValue, option.value]
      }
    }

    newValues.value[entity.id] = manualValue
    projectStore.updateField({
      ...field,
      manualValue,
    })
  })
}

/** Emit an update with the current selection values */
const emitUpdate = () => {
  if (activeSelectProperty.value && isDirty.value) {
    if (activeSelectProperty.value.type === PropertyType.multi_select) {
      emit('setValue', activeSelectProperty.value.id, newValues.value)
    } else {
      /**
       * It sucks that we have to do this, but there is an impossible to reproduce
       * bug (that has been observed happening on PostHog) where the values are
       * only set for a subset of the selected entities.
       *
       * This is a workaround for that, but we can only do it for single and user
       * select properties, because we know that we will be setting the same value
       * for all entities. Multi select properties can have different values for
       * each entity, so we can't use this workaround.
       */
      const selectedValue = Object.values(newValues.value)[0]
      emit('setValue', activeSelectProperty.value.id, {
        ...Object.fromEntries(Array.from(props.entities).map(({ id }) => [id, selectedValue])),
      })
    }
  }
  isDirty.value = false
  activeSelectId.value = null
}

const isMenuOpen = ref(false)
const onToggleMenu = () => {
  if (isMenuOpen.value) {
    emitUpdate()
  }
  isMenuOpen.value = !isMenuOpen.value
}

onBeforeUnmount(emitUpdate)

const valueToColorMap = computed(() =>
  Object.fromEntries(
    activeSelectProperty.value?.options.map((o) => [o.data.value, o.data.color]) ?? [],
  ),
)
</script>

<template>
  <PopupMenu
    :open="isMenuOpen"
    :auto-placement="placement"
    :offset="offset"
    trigger-element="div"
    :teleport-to="teleportTo"
    @change:open="onToggleMenu"
  >
    <template #trigger>
      <slot
        name="trigger"
        :is-open="isMenuOpen"
        :toggle-open="onToggleMenu"
      />
    </template>
    <template #dropdown>
      <ListMenu
        v-if="activeSelectProperty"
        :items="activeSelectProperty.options"
        search-by-field="name"
        :search-placeholder="`Set value for ${activeSelectProperty.name}`"
        @keydown.escape.stop="clearSelectProperty"
        @select="(item) => onClickOption(item)"
      >
        <template #item="{ active, item, key, setActiveItem }">
          <ListMenuCheckboxItem
            v-if="activeSelectProperty.type === PropertyType.multi_select"
            element="div"
            :label="item.data.value"
            :active="active"
            :aria-selected="active"
            always-visible
            :checked="optionStatus[item.data.value] === true"
            :indeterminate="optionStatus[item.data.value] === 'indeterminate'"
            @mousemove="setActiveItem(key)"
            @select="onClickOption(item.data)"
          >
            <div class="flex grow justify-start">
              <BadgeItem
                size="sm"
                :label="item.data.value"
                variant="blue"
                :rainbow-color="valueToColorMap[item.data.value]"
              />
            </div>
            <template #suffix>
              <div
                v-if="countByValue[item.data.value]"
                class="text-xs-11px-default text-text-subtlest"
              >
                {{ countByValue[item.data.value] }}
              </div>
            </template>
          </ListMenuCheckboxItem>
          <ListMenuItem
            v-else
            element="div"
            :label="item.data.value"
            :active="active"
            :aria-selected="active"
            @mousemove="setActiveItem(key)"
            @select="onClickOption(item.data)"
          >
            <template #prefix>
              <IconSprite
                :icon="optionStatus[item.data.value] === true ? 'check' : 'blank'"
                class="mr-1 text-icon-subtle"
              />
            </template>
            <div class="flex grow justify-start">
              <BadgeItem
                size="sm"
                :label="item.data.name"
                variant="blue"
                :rainbow-color="valueToColorMap[item.data.value]"
              />
            </div>
            <template #suffix>
              <div
                v-if="countByValue[item.data.value]"
                class="text-xs-11px-default text-text-subtlest"
              >
                {{ countByValue[item.data.value] }}
              </div>
            </template>
          </ListMenuItem>
        </template>
      </ListMenu>
      <ListMenu
        v-else
        :items="selectProperties"
        search-by-field="name"
        search-placeholder="Set value for"
        @select="onClickSelectProperty"
      >
        <template #item="{ active, item, key, setActiveItem }">
          <ListMenuItem
            element="div"
            :label="item.data.name"
            :aria-label="item.data.name"
            :active="active"
            :aria-selected="active"
            :icon="TYPE_ICON[item.data.type]"
            @mousemove="setActiveItem(key)"
            @select="onClickSelectProperty(item.data)"
          >
          </ListMenuItem>
        </template>
      </ListMenu>
    </template>
  </PopupMenu>
</template>
