<script setup lang="ts">
import { useAskGo } from '@/modules/Project/useAskGo'
import { useProject } from '@/modules/Project/useProject'
import AskGoSidebar from '@/modules/Workspaces/AskGoSidebar.vue'
import { useWorkspaces } from '@/modules/Workspaces/useWorkspaces'

const askGo = useAskGo()
const projectStore = useProject()
const workspacesStore = useWorkspaces()
</script>

<template>
  <div class="flex size-full bg-surface-tertiary">
    <div
      id="sidebar"
      class="flex min-w-2 overflow-hidden"
      data-test="sidebar"
    >
      <div class="flex size-full flex-col">
        <slot name="sidebar" />
      </div>
    </div>
    <div
      id="page-container"
      class="flex grow flex-col [contain:strict]"
    >
      <slot name="page-header" />
      <div
        class="min-h-0 grow overflow-y-auto rounded-tl-lg bg-surface-primary scrollbar-thin scrollbar-track-background-transparent scrollbar-thumb-background-gray-subtle scrollbar-track-rounded-md"
      >
        <slot name="page" />
      </div>
    </div>
    <AskGoSidebar
      v-if="
        workspacesStore.currentWorkspace &&
        askGo.isAskGoAvailable &&
        projectStore.projectId &&
        askGo.currentSession
      "
      :project-id="projectStore.projectId"
      :session-id="askGo.currentSession.id"
      :workspace-id="workspacesStore.currentWorkspace.id"
    />
  </div>
</template>
