<script setup lang="ts">
import VerifyEmailIllustration from '@/illustrations/verify-email.svg'
import DarwinButton from '@/uiKit/DarwinButton.vue'
import IconSprite from '@/uiKit/IconSprite.vue'
import { useAuth0 } from '@auth0/auth0-vue'

defineProps<{ message: string }>()

const { logout } = useAuth0()
</script>

<template>
  <div
    class="grid size-full gap-px bg-border-subtle [&>*]:bg-surface-primary-persist"
    :style="{
      gridTemplateColumns: 'minmax(0, 1fr) minmax(60%, auto) minmax(0, 1fr)',
      gridTemplateRows: 'minmax(0, 1fr) auto minmax(0, 1fr)',
    }"
  >
    <div />
    <div />
    <div />
    <div />
    <div class="flex h-max min-w-[70%] flex-col items-center gap-6 p-10">
      <VerifyEmailIllustration class="mb-6 w-[283px]" />
      <div class="text-center">
        <h1 class="mb-1 text-md-13px-bold text-text">Authentication Error</h1>
        <p
          v-if="message"
          class="text-sm-12px-light text-text-subtle"
        >
          {{ message }}
        </p>
      </div>
      <DarwinButton
        variant="neutral"
        size="md"
        @click="logout"
        >Retry
        <template #leading-icon>
          <IconSprite
            icon="refresh"
            class="text-icon-subtle"
          />
        </template>
      </DarwinButton>
    </div>
    <div />
    <div />
    <div />
    <div />
  </div>
</template>
