import type { SnakeToCamelCaseObj } from '@/shared/types'
import { camelCase } from 'change-case/keys'

/**
 * Recursively converts object keys from snake_case to camelCase, can be used when serializing objects from
 * the API.
 *
 * @example
 * ```ts
 * const serialized = serializeObject({
 *   some_prop: 'some_value',
 *   parent_key: { child_key: 'child_value' },
 *   array_key: [{ array_child_key: 'array_child_value' }],
 * })
 *
 * // serialized === {
 * //   someProp: 'some_value',
 * //   parentKey: { childKey: 'child_value' },
 * //   arrayKey: [{ arrayChildKey: 'array_child_value' }],
 * // }
 * ```
 */
export function serializeObject<T>(c: T[]): SnakeToCamelCaseObj<T>[]
export function serializeObject<T, U = SnakeToCamelCaseObj<T>>(c: T): U
export function serializeObject<T>(c: T | T[]): SnakeToCamelCaseObj<T> | SnakeToCamelCaseObj<T>[] {
  return camelCase(c, Infinity) as SnakeToCamelCaseObj<T> | SnakeToCamelCaseObj<T>[]
}
