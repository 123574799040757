/**
 * Thrown when user closes Google Drive picker (cancels, clicks on X, etc.)
 */
export class GooglePickerClosedError extends Error {
  constructor() {
    super('Google picker closed by the user')
  }
}

export class GooglePickerTimeoutError extends Error {
  constructor() {
    super('Google picker initialization timed out')
  }
}
